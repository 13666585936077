import { ChevronLeftIcon, ChevronRightIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { Button, ClickAwayListener, SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { UrlObject } from 'url';
import { shallow } from 'zustand/shallow';

import { AppRoute } from '@/_routes';
import IconContainer from '@/components/atoms/IconContainer/IconContainer';
import Notification from '@/components/atoms/Notification/Notification';
import TabLink from '@/components/atoms/TabLink/TabLink';
import { useLogout } from '@/hooks/useLogout';
import { useAuthStore } from '@/lib/store/auth/auth.store';
import { AuthState } from '@/lib/store/auth/auth.utils';
import { useNotificationsStore } from '@/lib/store/notifications/notifications.store';
import { useStyles } from '@/lib/styles/styles.hooks';
import { AppIcons } from '@/lib/utils/iconsLibrary';
import { NavigationLink } from '../../../others/Layout/Layout';
import NotificationStackList from '../../NotificationStackList/NotificationStackList';
import { BottomNavigationStyles } from './BottomNavigation.styles';

interface BottomNavigationProps {
  /** account navigation links */
  accountLinks?: NavigationLink[];
  /** bottom navigation links */
  bottomNavigationLinks?: NavigationLink[];
  /** menu navigation links */
  menuNavigationLinks?: NavigationLink[];
  /** styles */
  sx?: SxProps<Theme>;
}

const BottomNavigation: FC<BottomNavigationProps> = ({
  accountLinks,
  bottomNavigationLinks,
  menuNavigationLinks,
  sx,
}) => {
  const [isMenuNavigationOpen, setIsMenuNavigationOpen] = useState<boolean>(false);
  const [isAccountNavigationOpen, setIsAccountNavigationOpen] = useState<boolean>(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState<boolean>();

  const styles = useStyles(BottomNavigationStyles, { isMenuNavigationOpen }, sx);
  const { push: navigate } = useRouter();
  const logout = useLogout();

  const { unreadCount, hasNotifications, clearNotifications } = useNotificationsStore((state) => state);

  const { authState } = useAuthStore(
    (state) => ({
      authState: state.authState,
    }),
    shallow
  );

  function closeMenuNavigation() {
    setIsMenuNavigationOpen(false);
  }
  function closeProfilNavigation() {
    setIsAccountNavigationOpen(false);
  }
  function toggleMenuNavigation() {
    setIsMenuNavigationOpen(!isMenuNavigationOpen);
    setIsAccountNavigationOpen(false);
  }
  function openProfilNavigation() {
    setIsAccountNavigationOpen(true);
  }

  async function handleSignOut() {
    await setIsMenuNavigationOpen(false);
    await setIsAccountNavigationOpen(false);
    await logout();
  }

  async function handleBottomLinkClick(route: string | UrlObject) {
    await setIsMenuNavigationOpen(false);
    await navigate(route);
  }

  const handleOpenNotificationMenu = () => {
    if (hasNotifications) setIsNotificationMenuOpen(!isNotificationMenuOpen);
  };

  const handleClickAwayListener = (event: globalThis.MouseEvent | TouchEvent) => {
    event.preventDefault();
    setIsNotificationMenuOpen(false);
    clearNotifications();
  };

  return (
    <>
      {isMenuNavigationOpen && <Box sx={styles.menuCloseWrapper} onClick={closeMenuNavigation} />}
      {isMenuNavigationOpen && !isAccountNavigationOpen && (
        <Box sx={styles.menuNavigation} className="BottomNavigation-menuNavigation">
          <Button sx={styles.closingIcon} onClick={closeMenuNavigation} variant="tab" color="secondary">
            <IconContainer>
              <XIcon />
            </IconContainer>
          </Button>
          <Box sx={styles.linkContainer} className="BottomNavigation-linkContainer">
            {authState === AuthState.AUTHORIZED && (
              <Button onClick={openProfilNavigation} variant="tab" color="secondary" sx={styles.profilNavigationIcon}>
                <Typography variant="h5">Profil</Typography>
                <IconContainer>
                  <ChevronRightIcon />
                </IconContainer>
              </Button>
            )}
            {menuNavigationLinks?.map((link, id) => (
              <TabLink key={`MenuNavigation${id}`} href={link.href} label={link.label} />
            ))}
          </Box>
          {authState === AuthState.AUTHORIZED ? (
            <Button color="primary" variant="contained" onClick={handleSignOut}>
              Logout
            </Button>
          ) : (
            <Box sx={styles.unAuthButtonContainer}>
              <Button variant="borderless" onClick={() => navigate(AppRoute.REGISTRATION)}>
                Sign Up
              </Button>
              <Button color="primary" variant="contained" onClick={() => navigate(AppRoute.LOGIN)}>
                Sign In
              </Button>
            </Box>
          )}
        </Box>
      )}

      {isMenuNavigationOpen && isAccountNavigationOpen && (
        <Box sx={styles.menuNavigation} className="BottomNavigation-menuNavigation">
          <Box sx={styles.closingIconsContainer}>
            <Button variant="tab" color="secondary" onClick={closeProfilNavigation}>
              <IconContainer>
                <ChevronLeftIcon />
              </IconContainer>
            </Button>
            <Button variant="tab" color="secondary" onClick={closeMenuNavigation}>
              <IconContainer>
                <XIcon />
              </IconContainer>
            </Button>
          </Box>
          <Box sx={styles.linkContainer} className="BottomNavigation-linkContainer">
            {accountLinks?.map((link, id) => (
              <TabLink key={`MenuNavigation${id}`} href={link.href} label={link.label} />
            ))}
          </Box>
        </Box>
      )}

      <Box sx={styles.bottomContainer} className="BottomNavigation-container">
        {bottomNavigationLinks?.map((link, id) => {
          const isNotification = link.label === 'Notifications';
          return (
            <Button
              key={`BottomNavigation${id}`}
              variant="tab"
              color="secondary"
              onClick={() => handleBottomLinkClick(link.href)}
            >
              {isNotification && <Notification content={'12'} />}
              <IconContainer>{link.icon}</IconContainer>
            </Button>
          );
        })}

        <Box sx={{ position: 'relative' }}>
          <Button disabled={!hasNotifications} onClick={handleOpenNotificationMenu}>
            {AppIcons['BELL']}
          </Button>
          {unreadCount > 0 && <Notification content={unreadCount.toString()} />}
        </Box>

        <Button variant="tab" color="secondary" className="BottomNavigation-menuIcon" onClick={toggleMenuNavigation}>
          <IconContainer>
            <MenuIcon />
          </IconContainer>
        </Button>
      </Box>
      {isNotificationMenuOpen && (
        <ClickAwayListener onClickAway={(event) => handleClickAwayListener(event)}>
          <Box position={'absolute'} width={'100%'} display={'flex'} justifyContent={'center'}>
            <NotificationStackList />
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};

export default BottomNavigation;
