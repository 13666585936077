import { Rarity } from '@cstactics/types';
import { ComponentStylesWithFns } from '@/lib/styles/styles.types';
import { formatFromCamelCase } from '@/lib/utils/formatFromCamelCase';

const styles: ComponentStylesWithFns<{ rarity: Rarity }> = {
  root: ({ rarity }) => [
    {
      paddingY: 1,
      paddingX: 2,
      color: `rarity${formatFromCamelCase(rarity)}.light`,
      backdropFilter: 'blur(16px)',
      backgroundColor: `rarity${formatFromCamelCase(rarity)}.dark`,
      borderRadius: '16px',
      width: 'fit-content',
    },
  ],
};

export { styles as RarityTagStyles };
