import { SxProps, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { AppIcons } from '@/lib/utils/iconsLibrary';
import { theme } from '../../../../theme/theme';
import TabLink from '@/components/atoms/TabLink/TabLink';
import { NavigationLink } from '../../../others/Layout/Layout';
import { SideNavigationStyles } from './SideNavigation.styles';
import { TicketIcon } from '@heroicons/react/outline';

interface SideNavigationProps {
  sideNavigationLinks?: NavigationLink[];
  disableNavigation?: boolean;
  /** styles */
  sx?: SxProps<Theme>;
}

const SideNavigation: FC<SideNavigationProps> = ({ sideNavigationLinks, disableNavigation = false, sx }) => {
  const styles = useStyles(SideNavigationStyles, {}, sx);

  const isLarge = useMediaQuery(theme.breakpoints.only('large'));

  return (
    <Box sx={{ ...styles.root, filter: disableNavigation ? 'blur(2px)' : 'none' }} className="SideNavigation-root">
      <Box sx={styles.top}>
        {sideNavigationLinks?.map((link, id) => (
          <TabLink key={`SideNavigation${id}`} href={link.href} label={!isLarge ? link.label : ''} icon={link.icon} />
        ))}
      </Box>
      <Box sx={styles.bottom}>
        <TabLink href={'/redeem-voucher'} label={!isLarge ? 'Redeem Voucher' : ''} icon={<TicketIcon />} />
        <TabLink
          href={'/help'}
          label={!isLarge ? 'Help & Getting Started' : ''}
          icon={AppIcons['QUESTION_MARK_CIRCLE']}
        />
      </Box>
    </Box>
  );
};

export default SideNavigation;
