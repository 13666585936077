import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    width: ['100%', '100%', 'calc(100vw - 84px)', 'calc(100vw - 287px)'],
    position: 'absolute',
    top: [60, 68, 84, 84],
    left: [0, 0, 84, 270],
  },
  breadcrumbsContainer: {
    height: '100%',

    paddingX: [3, 3, 4, 7],
    backgroundColor: 'secondary.transparent',
    backdropFilter: 'blur(10px)',
    paddingY: [3],
  },
};

export { styles as AppBreadcrumbsStyles };
