import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Rarity } from '@cstactics/types';
import { FC } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { formatFromCamelCase } from '@/lib/utils/formatFromCamelCase';
import { RarityTagStyles } from './RarityTag.styles';

interface RarityTagProps {
  /** rarity */
  rarity?: Rarity;

  /** styles */
  sx?: SxProps<Theme>;
}

const RarityTag: FC<RarityTagProps> = ({ rarity, sx }) => {
  const styles = useStyles(RarityTagStyles, { rarity }, sx);

  return (
    <Box className="RarityTag-root" sx={styles.root}>
      <Typography variant="body">{formatFromCamelCase(rarity || '')}</Typography>
    </Box>
  );
};

export default RarityTag;
