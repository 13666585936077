import { Typography } from '@mui/material';
import { useStyles } from '@/lib/styles/styles.hooks';
import { NotificationStyles } from './Notification.styles';

const Notification = ({ content }: { content: string }) => {
  const styles = useStyles(NotificationStyles, {});
  return <Typography sx={styles.root}>{content}</Typography>;
};

export default Notification;
