import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  layoutWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'bgPrimary.primary',
    color: 'contentPrimary.primary',
    boxSizing: 'border-box',
  },
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    //minHeight: '100vh',
    //overflow: 'auto',
    position: 'relative',
    boxSizing: 'border-box',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    //width: ['100%', '100%', 'calc(100% - 84px)', 'calc(100% - 270px)'],
    height: '100%',
    minHeight: ['calc(100vh - 60px)', 'calc(100vh - 68px)', 'calc(100vh - 84px)', 'calc(100vh - 84px)'],
    marginLeft: [0, 0, '84px', '270px'],
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
  },
  mainContent: {
    width: '100%',
    height: '100%',

    // TODO: add height and overflow for sticky page banner
    // maxHeight: '',
    // overflow: 'auto',
  },
};

export { styles as LayoutStyles };
