import { ListItemButton, ListItemIcon, MenuItem, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { useRouter } from 'next/router';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import IconContainer from '@/components/atoms/IconContainer/IconContainer';
import SimpleCheckbox from '@/components/atoms/SimpleCheckbox/SimpleCheckbox';
import SimpleRadio from '@/components/atoms/SimpleRadio/SimpleRadio';
import { DropdownItemStyles } from './DropdownItem.styles';

export interface DropdownItemProps {
  icon?: JSX.Element;
  name?: string;
  checkIcon?: 'checkbox' | 'radio';
  label?: string;
  index?: number;
  disabled?: boolean;
  id?: string | number;
  sx?: SxProps<Theme>;
  route?: string;
  selectFieldValue?: string | string[];
  setSelectFieldValue?: Dispatch<SetStateAction<string | string[]>>;
  multiple?: boolean;
  dropdownValue?: string;
  onClick?: (index: number) => void;
}

const DropdownItem: FC<DropdownItemProps> = ({
  icon,
  // name,
  checkIcon,
  label,
  index,
  selectFieldValue,
  setSelectFieldValue,
  route,
  disabled,
  multiple,
  id,
  sx,
  dropdownValue,
  onClick,
}) => {
  function convertStringToArray(param: string | string[]): string[] {
    if (Array.isArray(param)) {
      return param;
    } else {
      return [param];
    }
  }

  function checkmark(value: string, container: string | string[]): boolean {
    return container.includes(value);
  }

  const styles = useStyles(DropdownItemStyles, { disabled }, sx);
  const router = useRouter();
  const [checked, setChecked] = useState(
    dropdownValue && selectFieldValue ? checkmark(dropdownValue, selectFieldValue) : false
  );

  useEffect(() => {
    if (!multiple) {
      setChecked(dropdownValue && selectFieldValue ? checkmark(dropdownValue, selectFieldValue) : false);
    }
  }, [selectFieldValue]);

  const handleSelection = () => {
    // remove uncertainty of undefined values
    if (setSelectFieldValue && selectFieldValue !== undefined && dropdownValue) {
      // if multi-selection is enanbled, array of strings will be needed
      if (multiple && !Array.isArray(selectFieldValue)) {
        convertStringToArray(selectFieldValue);
      }
      // case 1: item was unchecked and has to be added
      if (!checkmark(dropdownValue, selectFieldValue)) {
        // case 1a: multi-selection is enabled, item has to be added to an array
        if (multiple) {
          if (selectFieldValue === '') {
            setSelectFieldValue(dropdownValue);
          } else {
            if (Array.isArray(selectFieldValue)) {
              const fieldValueArray: string[] = selectFieldValue.concat(convertStringToArray(dropdownValue));
              setSelectFieldValue(fieldValueArray);
            } else {
              const fieldValueArray: string[] = convertStringToArray(selectFieldValue).concat(
                convertStringToArray(dropdownValue)
              );
              setSelectFieldValue(fieldValueArray);
            }
          }
          // case 1b: only single selection, item is string
        } else {
          setSelectFieldValue(dropdownValue);
        }
        // case 2: item was checked and has to be deleted
      } else if (checkmark(dropdownValue, selectFieldValue)) {
        // case 2a: multi-selection, item has to be removed from array
        if (multiple) {
          if (Array.isArray(selectFieldValue)) {
            const index = selectFieldValue.indexOf(dropdownValue);
            setSelectFieldValue((selectFieldValueArray) =>
              convertStringToArray(selectFieldValueArray).filter((value, i) => i !== index)
            );
          } else {
            setSelectFieldValue('');
          }
          // case 2b: single selection, item has to be removed and fieldValue set to empty
        } else {
          setSelectFieldValue('');
        }
      }
    }
    if (multiple) {
      setChecked(!checked);
    }
  };

  const handleClick = (index: number) => {
    // case disabled -> no action needed
    if (disabled) return;
    // case checkIcon set -> selectable component -> check/uncheck checkbox or radio button and setFieldValue of parent component
    if (checkIcon) {
      handleSelection();
    }
    if (route) {
      router.push(route);
    }
    if (onClick) {
      onClick(index);
    }
  };

  // if (!route || !checkIcon) {
  //   return (
  //     <MenuItem disabled={disabled} className={'dropdownItem-menuItem'} sx={styles.root} value={index?.toString()}>
  //       {/* <ListItemButton disabled={disabled}> */}
  //       <Typography variant="h5">
  //         {label} value: {index}
  //       </Typography>
  //       {/* </ListItemButton> */}
  //     </MenuItem>
  //   );
  // }

  return (
    <MenuItem
      className={'dropdownItem-menuItem'}
      sx={styles.root}
      onClick={() => handleClick(index || 0)}
      key={id}
      value={index ? index.toString() : ''}
    >
      <ListItemButton disabled={disabled}>
        {icon ||
          (checkIcon && (
            <ListItemIcon>
              {icon ? (
                <IconContainer sx={styles.dropdownIconContainer} className="DropdownIcon-iconContainer">
                  {icon}
                </IconContainer>
              ) : checkIcon === 'checkbox' ? (
                <SimpleCheckbox checked={checked} />
              ) : checkIcon === 'radio' ? (
                <SimpleRadio checked={checked} />
              ) : (
                ''
              )}
            </ListItemIcon>
          ))}
        <Typography variant="h5">{label}</Typography>
      </ListItemButton>
    </MenuItem>
  );
};

export default DropdownItem;
