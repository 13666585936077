import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { LineStyles } from './Line.styles';

interface LineProps {
  sx?: SxProps<Theme>;
}

const Line: FC<LineProps> = ({ sx }) => {
  const styles = useStyles(LineStyles, {}, sx);

  return <Box sx={styles.root}></Box>;
};

export default Line;
