import { createSvgIcon } from '@mui/material/utils';
import Bank from './bank.svg';
import Menu from './menu.svg';
import Tactics from './tactics.svg';
import Training from './training.svg';
import Trophy from './trophy.svg';
import SoftCurrency from '../../../../../public/icons/soft-currency.svg';
import SoftCurrencyBlack from '../../../../../public/icons/soft-currency-black.svg';
import PremiumCurrency from '../../../../../public/icons/premium-currency.svg';
import PremiumCurrencyWhite from '../../../../../public/icons/premium-currency-white.svg';
import PremiumCurrencyBlack from '../../../../../public/icons/premium-currency-black.svg';
import Inventory from '../../../../../public/icons/inventory.svg';

export const MenuIcon = createSvgIcon(<Menu />, 'Menu');
export const BankIcon = createSvgIcon(<Bank />, 'Bank');
export const TacticsIcon = createSvgIcon(<Tactics />, 'Tactics');
export const TrainingIcon = createSvgIcon(<Training />, 'Training');
export const TrophyIcon = createSvgIcon(<Trophy />, 'Trophy');
export const SoftCurrencyIcon = createSvgIcon(<SoftCurrency />, 'SoftCurrency');
export const SoftCurrencyBlackIcon = createSvgIcon(<SoftCurrencyBlack />, 'SoftCurrencyBlack');
export const PremiumCurrencyIcon = createSvgIcon(<PremiumCurrency />, 'PremiumCurrency');
export const PremiumCurrencyWhiteIcon = createSvgIcon(<PremiumCurrencyWhite />, 'PremiumCurrencyWhite');
export const PremiumCurrencyBlackIcon = createSvgIcon(<PremiumCurrencyBlack />, 'PremiumCurrencyBlack');
export const InventoryIcon = createSvgIcon(<Inventory />, 'Inventory');
