import AppLink from '@/components/atoms/AppLink/AppLink';
import { useGameAssetsStore } from '@/lib/store/gameAssets/game-assets.store';
import { useStyles } from '@/lib/styles/styles.hooks';
import { formatFromCamelCase } from '@/lib/utils/formatFromCamelCase';
import { getCardTitle } from '@/lib/utils/getCardTitle';
import { Breadcrumbs, Stack, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { Card } from '@cstactics/types';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { AppBreadcrumbsStyles } from './AppBreadcrumbs.styles';

interface AppBreadcrumbsProps {
  /** styles */
  sx?: SxProps<Theme>;
}

const pageData = [
  {
    id: 1,
    page: '/promotion/',
    breadcrumbs: [<AppLink label="Home" href="/" key={1} />, <AppLink label="Shop" href="/promotion" key={2} />],
  },
  {
    id: 2,
    page: '/pack/',
    breadcrumbs: [<AppLink label="Home" href="/" key={1} />, <AppLink label="Inventory" href="/profile" key={2} />],
  },
  {
    id: 3,
    page: '/card/',
    breadcrumbs: [
      <AppLink label="User" href="/profile" key={1} />,
      <AppLink label="Inventory" href="/profile" key={2} />,
    ],
  },
];

const AppBreadcrumbs: FC<AppBreadcrumbsProps> = ({ sx }) => {
  const styles = useStyles(AppBreadcrumbsStyles, {}, sx);
  const [activePageBreadcrumbs, setActivePageBreadcrumbs] = useState<any>();
  const [activePage, setActivePage] = useState<string>();

  const [cardInfo, setCardInfo] = useState<Card>();
  const [cardTitle, setCardTitle] = useState<{ title: string }>();
  const [packTitle, setPackTitle] = useState<{ title: string }>();

  const router = useRouter();
  const { id } = router.query;
  const path = router.pathname;

  const { getCardPack, getCard } = useGameAssetsStore((state) => ({
    getCardPack: state.getCardPack,
    getCard: state.getCard,
  }));

  useEffect(() => {
    if (typeof id !== 'string') return;

    const _activePack = getCardPack(id);
    setPackTitle({ title: _activePack?.name! });

    const _activeCard = getCard(id);
    setCardInfo(_activeCard);
  }, [id]);

  useEffect(() => {
    if (!cardInfo) return;

    setCardTitle({
      title: getCardTitle(cardInfo),
    });
  }, [cardInfo]);

  useEffect(() => {
    const pathSplit = path.split('[')[0];
    const pathArray = router.asPath.split('/');
    const pathEndpoint = pathArray[pathArray.length - 1]
      .split('-')
      .map((item) => formatFromCamelCase(item))
      .join(' ');

    setActivePage(pathEndpoint);
    const page = pageData.find((page) => page.page === pathSplit);
    if (page?.page.includes('pack')) {
      setCardTitle(undefined);
    }
    setActivePageBreadcrumbs(page);
  }, [router]);

  return (
    <Box className="AppBreadcrumbs-root" sx={styles.root}>
      {activePageBreadcrumbs?.breadcrumbs && (
        <Box sx={styles.breadcrumbsContainer}>
          <Stack>
            <Breadcrumbs separator="">
              {activePageBreadcrumbs?.breadcrumbs}
              {cardTitle && (
                <AppLink
                  label={cardTitle.title}
                  variant="text"
                  textVariant="h5"
                  color="secondary"
                  hasArrowIcon={false}
                  disabled={true}
                />
              )}
              {packTitle && (
                <AppLink
                  label={packTitle.title}
                  variant="text"
                  textVariant="h5"
                  color="secondary"
                  hasArrowIcon={false}
                  disabled={true}
                />
              )}
              {!cardTitle && !packTitle?.title && (
                <AppLink
                  label={activePage}
                  variant="text"
                  textVariant="h5"
                  color="secondary"
                  hasArrowIcon={false}
                  disabled={true}
                />
              )}
            </Breadcrumbs>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default AppBreadcrumbs;
