import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    height: 'content-fit',
    width: '100%',
    marginTop: [4],
    marginRight: [3],
    backgroundColor: 'bgPrimary.primary',
    borderRadius: [1],
    border: '1px solid',
    borderColor: 'contentSecondary.tertiary',
    zIndex: 1000,
    padding: [3],
    display: 'flex',
    flexDirection: 'column',
    gap: [3],
  },
};

export { styles as DropdownListStyles };
