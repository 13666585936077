import { useStyles } from '@/lib/styles/styles.hooks';
import { CheckIcon } from '@heroicons/react/outline';
import { Box, Checkbox, CheckboxProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import IconContainer from '../IconContainer/IconContainer';
import { SimpleCheckboxStyles } from './SimpleCheckbox.styles';

interface SimpleCheckboxProps extends CheckboxProps {
  disabled?: boolean;
  name?: string;
  // color?: 'primary' | 'secondary' | 'accent';
  sx?: SxProps<Theme>;
  checked?: boolean;
}

const SimpleCheckbox: FC<SimpleCheckboxProps> = ({ disabled, checked, color = 'accent', sx, ...rest }) => {
  const styles = useStyles(SimpleCheckboxStyles, { color }, sx);

  return (
    <Checkbox
      sx={styles.root}
      icon={<Box></Box>}
      disabled={disabled}
      checked={checked}
      checkedIcon={
        <IconContainer sx={styles.checkbox} className={'SimpleCheckbox-checkIcon'}>
          <CheckIcon />
        </IconContainer>
      }
      {...rest}
    />
  );
};

export default SimpleCheckbox;
