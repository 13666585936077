import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    height: '100%',
    width: '100%',
    paddingTop: 7,
    marginBottom: 7,

    zIndex: 99,
    borderTop: '1px solid',
    borderColor: 'bgPrimary.tertiary',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginTop: 'auto',
  },
  container: {
    maxWidth: ['342px', '568px', '852px', '1152px'],
  },

  // Top Container
  topContainer: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: ['1fr 1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
    gridTemplateRows: ['auto'],
    gridAutoRows: 'minmax(min-content, max-content)',
    gap: '48px 24px',
  },
  logoContainer: {
    height: '100%',
    width: '100%',
    paddingBottom: [0, 3, 0, 0],
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: [3],
  },
  title: {
    marginBottom: [2],
  },
  line: {
    marginY: [4],
    backgroundColor: 'bgPrimary.tertiary',
  },
  logo: {
    position: 'relative',
    height: 24,
    maxWidth: '115.73px',
    marginBottom: [4],
  },

  // Bottom Container
  bottomContainer: {
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: ['column-reverse', 'row', 'row', 'row'],
    paddingY: [0, 3, 3, 3],
    justifyContent: 'space-between',
    alignItems: ['flex-start', 'center', 'center', 'center'],
  },
  copyright: {
    color: 'contentSecondary.tertiary',
    display: 'inline-block',
    marginTop: [3, 0, 0, 0],
  },
  iconContainer: {
    display: 'flex',
    flexDirection: ['column', 'row', 'row', 'row'],
    gap: [3],
  },
  button: {
    width: 52,
    height: 52,
    padding: [0],
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export { styles as FooterStyles };
