import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, ReactNode } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { DropdownListStyles } from './DropdownList.styles';

interface DropdownListProps {
  children: ReactNode;
  /** styles */
  sx?: SxProps<Theme>;
}

const DropdownList: FC<DropdownListProps> = ({ sx, children }) => {
  const styles = useStyles(DropdownListStyles, {}, sx);

  return (
    <Box sx={styles.root} className="DropdownList-root">
      {children}
    </Box>
  );
};

export default DropdownList;
