import {
  BankIcon,
  InventoryIcon,
  PremiumCurrencyBlackIcon,
  PremiumCurrencyIcon,
  PremiumCurrencyWhiteIcon,
  SoftCurrencyBlackIcon,
  SoftCurrencyIcon,
  TacticsIcon,
  TrainingIcon,
  TrophyIcon,
} from '@/components/atoms/Icons/MenuIcons/MenuIcons';
import {
  BellIcon,
  ChartPieIcon,
  CheckIcon,
  ChevronDownIcon,
  CollectionIcon,
  CreditCardIcon,
  GlobeAltIcon,
  HomeIcon,
  MenuIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
  ShoppingBagIcon,
  UserGroupIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';

// TODO: fix types
interface AppIcons {
  [propName: string]: JSX.Element;
}

export const AppIcons: AppIcons = {
  GLOBE: <GlobeAltIcon />,
  DASHBOARD: <ViewGridIcon />,
  TEAM: <UserGroupIcon />,
  ATHLETES_HUB: <CollectionIcon />,
  FACILITY: <OfficeBuildingIcon />,
  TROPHY: <TrophyIcon />,
  STATISTICS: <ChartPieIcon />,
  SHOP: <ShoppingBagIcon />,
  QUESTION_MARK_CIRCLE: <QuestionMarkCircleIcon />,
  SEARCH: <SearchIcon />,
  CREDITCARD: <CreditCardIcon />,
  BELL: <BellIcon />,
  CHEVRONDOWN: <ChevronDownIcon />,
  HOME: <HomeIcon />,
  CHECK: <CheckIcon />,
  BURGER_MENU: <MenuIcon />,
  BANK: <BankIcon />,
  TRAINING: <TrainingIcon />,
  TACTICS: <TacticsIcon />,
  SOFT_CURRENCY: <SoftCurrencyIcon />,
  SOFT_CURRENCY_BLACK: <SoftCurrencyBlackIcon />,
  PREMIUM_CURRENCY: <PremiumCurrencyIcon />,
  PREMIUM_CURRENCY_BLACK: <PremiumCurrencyBlackIcon />,
  PREMIUM_CURRENCY_WHITE: <PremiumCurrencyWhiteIcon />,
  INVENTORY: <InventoryIcon />,
};
