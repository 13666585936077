import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';

interface SimpleRadioStyleProps extends StyleStates {
  color: 'primary' | 'secondary' | 'accent';
}

const styles: ComponentStylesWithFns<SimpleRadioStyleProps> = {
  root: ({ color }) => [
    {
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      border: '1px solid',
      borderRadius: '50%',
    },
    color === 'primary' && {
      'borderColor': 'bgPrimary.primary',
      'backgroundColor': 'bgPrimary.primary',
      '&.Mui-checked': {
        backgroundColor: 'bgPrimary.primary',
        borderColor: 'contentPrimary.primary',
      },
      '&:hover': {
        borderColor: 'contentPrimary.primary',
        backgroundColor: 'bgPrimary.primary',
      },
    },
    color === 'secondary' && {
      'borderColor': 'bgPrimary.secondary',
      'backgroundColor': 'bgPrimary.secondary',
      '&.Mui-checked': {
        backgroundColor: 'bgPrimary.secondary',
        borderColor: 'contentPrimary.secondary',
      },
      '&:hover': {
        borderColor: 'contentPrimary.secondary',
        backgroundColor: 'bgPrimary.secondary',
      },
    },
    color === 'accent' && {
      'borderColor': 'bgPrimary.secondary',
      'backgroundColor': 'bgPrimary.secondary',
      '&.Mui-checked': {
        backgroundColor: 'bgPrimary.secondary',
        borderColor: 'contentSecondary.tertiary',
      },
      '&:hover': {
        borderColor: 'contentSecondary.tertiary',
        backgroundColor: 'bgPrimary.secondary',
      },
    },
  ],
  checked: ({ color }) => [
    {
      width: 8,
      height: 8,
      borderRadius: '50%',
    },
    color === 'primary' && {
      backgroundColor: 'contentPrimary.primary',
    },
    color === 'secondary' && {
      backgroundColor: 'contentPrimary.secondary',
    },
    color === 'accent' && {
      backgroundColor: 'accent.main',
    },
  ],
};

export { styles as SimpleRadioStyles };
