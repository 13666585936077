import { AthleteCard } from '@cstactics/types';
import { StateCreator } from 'zustand';
import {
  ItemFilterBarFilter,
  ItemFilterBarSortByOption,
  ItemFilterBarSortByOptions,
} from '@/components/molecules/ItemFilterBar/ItemFilterBar.types';
import { UiState } from '../ui.store';
import { teamBenchFilterState, teamBenchSortByOptions } from './teamBench.filter';

export interface TeamBenchSlice {
  /** states */
  teamBenchFilterState: ItemFilterBarFilter<AthleteCard>[];
  teamBenchSortByState: ItemFilterBarSortByOption<AthleteCard>;
  teamBenchSortByOptions: ItemFilterBarSortByOptions<AthleteCard>;

  /** actions */
  setTeamBenchFilterState: (filters: ItemFilterBarFilter<AthleteCard>[]) => void;
  setTeamBenchSortByState: (sortByOption: ItemFilterBarSortByOption<AthleteCard>) => void;
}

export const createTeamBenchSlice: StateCreator<UiState, [], [], TeamBenchSlice> = (set) => ({
  /** states */
  teamBenchFilterState,
  teamBenchSortByOptions,
  teamBenchSortByState: teamBenchSortByOptions.options[0],

  /** actions */
  setTeamBenchFilterState: (teamBenchFilterState: ItemFilterBarFilter<AthleteCard>[]) => {
    set({ teamBenchFilterState });
  },
  setTeamBenchSortByState: (teamBenchSortByState: ItemFilterBarSortByOption<AthleteCard>) => {
    set({ teamBenchSortByState });
  },
});
