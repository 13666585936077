import { StateCreator } from 'zustand';
import { UiState } from '../ui.store';

export interface MatchSimulationSlice {
  matchSimulationPlayerState: 'pause' | 'play';
  playMode: 'Normal' | 'Fast' | 'Super Fast';
  skipFwdTrigger: number;
  playerCurrentSecond: number;
  playerTotalSeconds: number;
  activeRound: number;
  vetoHistoryDone: boolean;
  athleteInAction: {
    athleteId: number;
    type: 'CT' | 'T';
  } | null;
  matchSimulationResult: {
    win: number;
    loss: number;
    simulationDone: boolean;
  };
  setMatchSimulationPlayerState: (matchSimulationPlayerState: 'pause' | 'play') => void;
  setPlayMode: (playMode: 'Normal' | 'Fast' | 'Super Fast') => void;
  setSkipFwdTrigger: () => void;
  setPlayerCurrentSecond: (playerCurrentSecond: number) => void;
  setPlayerTotalSeconds: (playerTotalSeconds: number) => void;
  setActiveRound: (currentRound: number) => void;
  setVetoHistoryDone: (val: boolean) => void;
  setAthleteInAction: (
    athleteInAction: {
      athleteId: number;
      type: 'CT' | 'T';
    } | null
  ) => void;
  setMatchSimulationResult: ({
    win,
    loss,
    simulationDone,
    reset,
  }: {
    win?: number;
    loss?: number;
    simulationDone?: boolean;
    reset?: boolean;
  }) => void;
}

export const createMatchSimulationSlice: StateCreator<UiState, [], [], MatchSimulationSlice> = (set) => ({
  /** states */
  matchSimulationPlayerState: 'pause',
  playMode: 'Normal',
  skipFwdTrigger: 0,
  playerCurrentSecond: 0,
  playerTotalSeconds: 0,
  activeRound: 0,
  vetoHistoryDone: false,
  athleteInAction: null,
  matchSimulationResult: {
    win: 0,
    loss: 0,
    simulationDone: false,
  },

  /** actions */
  setMatchSimulationPlayerState: (matchSimulationPlayerState: 'pause' | 'play') => {
    set({ matchSimulationPlayerState });
  },
  setPlayMode: (playMode: 'Normal' | 'Fast' | 'Super Fast') => {
    set({ playMode });
  },
  setSkipFwdTrigger: () => {
    set((state) => ({ skipFwdTrigger: state.skipFwdTrigger + 1, matchSimulationPlayerState: 'pause' }));
  },
  setPlayerCurrentSecond: (playerCurrentSecond: number) => {
    set({ playerCurrentSecond: playerCurrentSecond * 10 });
  },
  setPlayerTotalSeconds: (playerTotalSeconds: number) => {
    set({ playerTotalSeconds: playerTotalSeconds * 10 });
  },
  setActiveRound: (activeRound: number) => {
    set({ activeRound });
  },
  setVetoHistoryDone: (vetoHistoryDone: boolean) => {
    set({ vetoHistoryDone });
  },
  setAthleteInAction: (athleteInAction: { athleteId: number; type: 'CT' | 'T' } | null) => {
    set({ athleteInAction });
  },
  setMatchSimulationResult: ({
    win,
    loss,
    simulationDone,
    reset,
  }: {
    win?: number;
    loss?: number;
    simulationDone?: boolean;
    reset?: boolean;
  }) => {
    set((state) => {
      if (reset) {
        return {
          matchSimulationResult: {
            win: 0,
            loss: 0,
            simulationDone: false,
          },
        };
      }

      return {
        matchSimulationResult: {
          win: win ? state.matchSimulationResult.win + win : state.matchSimulationResult.win,
          loss: loss ? state.matchSimulationResult.loss + loss : state.matchSimulationResult.loss,
          simulationDone: simulationDone ?? state.matchSimulationResult.simulationDone,
        },
      };
    });
  },
});
