import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  menuCloseWrapper: {
    position: 'fixed',
    inset: 0,
    zIndex: 1001,
  },
  menuNavigation: {
    zIndex: 9999,
    height: ['calc(100vh - 60px - 132px)', 'calc(100vh - 68px - 132px)', 0, 0],

    position: 'fixed',
    top: [84, 92, 0, 0],
    bottom: [216, 216, 0, 0],
    alignSelf: 'center',

    width: [342, 568, 0, 0],
    boxSizing: 'border-box',
    backgroundColor: 'bgPrimary.secondary',
    borderRadius: 1,
    padding: 1,
    border: '1px solid',
    borderColor: 'contentSecondary.tertiary',

    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    overflowY: 'auto',
  },
  profilNavigationIcon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  closingIcon: {
    width: 'fit-content',
    alignSelf: 'flex-end',
  },
  closingIconsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkContainer: {
    flexGrow: 1,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 4,
    overflowY: 'auto',
  },
  logoutContainer: {
    height: 'fit-content',
    width: '100%',
  },
  bottomContainer: {
    position: 'fixed',
    bottom: [24, 24, 0, 0],
    width: [342, 568, 0, 0],
    alignSelf: 'center',
    zIndex: 9999,
    height: 'fit-content',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'bgPrimary.secondary',
    padding: 1,

    borderRadius: 1,
    border: '1px solid',
    borderColor: 'contentSecondary.tertiary',
  },
  unAuthButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

export { styles as BottomNavigationStyles };
