import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';

interface TabLinkStyleProps extends StyleStates {
  fullWidth: boolean;
}

const styles: ComponentStylesWithFns<TabLinkStyleProps> = {
  root: ({ fullWidth }) => [
    {
      'height': 'fit-content',
      'boxSizing': 'border-box',
      'padding': '14px',
      'display': 'flex',
      'gap': 3,
      'cursor': 'pointer',
      'borderRadius': 1,
      '&:hover': {
        backgroundColor: 'secondary.transparent',
        color: 'contentPrimary.primary',
      },
    },
    fullWidth
      ? {
          width: '100%',
        }
      : {
          width: 'fit-content',
        },
  ],
  icon: {
    height: 24,
    width: 24,
    alignSelf: 'center',
    svg: {
      height: '100%',
      width: '100%',
    },
  },
};

export { styles as TabLinkStyles };
