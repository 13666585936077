import { useStyles } from '@/lib/styles/styles.hooks';
import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useEffect, useState } from 'react';
import { NotificationTimeTagStyles } from './NotificationTimeTag.styles';

interface NotificationTimeTagProps {
  dateObject?: Date;
  /** styles */
  sx?: SxProps<Theme>;
}

const NotificationTimeTag: FC<NotificationTimeTagProps> = ({ dateObject, sx }) => {
  const styles = useStyles(NotificationTimeTagStyles, {}, sx);

  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');

  useEffect(() => {
    let dateOptions: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
    };
    let timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    if (!dateObject) return;
    setDate(new Intl.DateTimeFormat('en-US', dateOptions).format(dateObject));
    setTime(new Intl.DateTimeFormat('en-US', timeOptions).format(dateObject));
  }, [dateObject]);

  return (
    <Box sx={styles.root} className="NotificationTimeTag-root">
      {date && <Typography variant="h5">{date}</Typography>}
      {date && time && <Typography variant="h5">•</Typography>}
      {time && <Typography variant="h5">{time}</Typography>}
    </Box>
  );
};

export default NotificationTimeTag;
