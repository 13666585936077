import { useStyles } from '@/lib/styles/styles.hooks';
import { Button, SxProps, Theme, Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import NotificationTimeTag from '../NotificationTimeTag/NotificationTimeTag';
import { AppNotificationStyles } from './AppNotification.styles';
import { UserNotification, UserNotificationType } from '@cstactics/types';

interface AppNotificationProps extends BoxProps {
  notification: UserNotification;
  isLast?: boolean;
  /** styles */
  sx?: SxProps<Theme>;
}

const AppNotification: FC<AppNotificationProps> = ({ isLast = false, notification, ...rest }) => {
  const [notificationVariant, setNotificationVariant] = useState<UserNotificationType>(UserNotificationType.DEFAULT);
  const [date, setDate] = useState<Date>();
  const styles = useStyles(AppNotificationStyles, { variant: notificationVariant, isLast });

  useEffect(() => {
    setDate(new Date(notification.date));
    setNotificationVariant(notification.type);
  }, [notification]);

  const router = useRouter();

  const handleClick = () => {
    if (notification?.action?.route) router.push(notification?.action.route);
  };

  return (
    <Box sx={styles.root} className="AppNotification-root" {...rest}>
      <Typography variant="h4">{notification.title}</Typography>
      <Typography variant="body">{notification.description}</Typography>
      {notification?.action?.route && (
        <Button variant="contained" onClick={handleClick}>
          {notification.action.label}
        </Button>
      )}
      <Box display={'flex'} justifyContent={'space-between'}>
        <NotificationTimeTag dateObject={date} />
        {!notification.isRead && (
          <Typography variant="h6" color={'info.main'}>
            NEW
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AppNotification;
