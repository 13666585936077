import { AppAthleteCardStatus } from '@/components/molecules/CardMolecules/AppAthleteCard/AppAthleteCard';
import {
  ItemFilterBarFilter,
  ItemFilterBarSortByOptions,
} from '@/components/molecules/ItemFilterBar/ItemFilterBar.types';
import { AthleteCard, csRoles } from '@cstactics/types';
import { isAthleteCardBlocked } from '../../gameAssets/cards/cards.actions';
import { isAthleteInTeam } from '../../gameAssets/teams/teams.actions';

export const athleteHubFilterState: ItemFilterBarFilter<AthleteCard>[] = [
  {
    name: 'search',
    label: 'Looking for athlete?',
    type: 'input',
    value: '',
    filterFn: (item, value) =>
      item.content.nickName.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
      item.content.firstName.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
      item.content.lastName.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
  },
  {
    name: 'status',
    label: 'Status',
    type: 'select',
    value: 'ALL',
    filterFn: (item, value) => {
      if (value === AppAthleteCardStatus.ACTIVE) {
        return isAthleteInTeam(item.uuid);
      } else if (value === AppAthleteCardStatus.INACTIVE) {
        return !isAthleteInTeam(item.uuid) && !isAthleteCardBlocked(item);
      } else if (value === AppAthleteCardStatus.BLOCKED) {
        return isAthleteCardBlocked(item);
      }
      return false;
    },
    options: [
      { value: AppAthleteCardStatus.ACTIVE, label: 'HIRED' },
      { value: AppAthleteCardStatus.INACTIVE, label: 'AVAILABLE' },
      { value: AppAthleteCardStatus.BLOCKED, label: 'BLOCKED' },
    ],
  },
  {
    name: 'role',
    label: 'Role',
    type: 'select',
    value: 'ALL',
    filterFn: (item, value) => item.content.proficiencies.roles.primary === value,
    options: csRoles.map((role) => ({ value: role, label: role })),
  },
];

enum AthleteGridSortByOption {
  ASC = 'asc',
  DESC = 'desc',
  POWER_HIGHT_TO_LOW = 'power hight to low',
  POWER_LOW_TO_HIGH = 'power low to high',
}

const { compare } = new Intl.Collator();

export const athleteHubSortByOptions: ItemFilterBarSortByOptions<AthleteCard> = {
  label: 'Sort By',
  options: [
    {
      label: 'nickname (asc)',
      value: AthleteGridSortByOption.ASC,
      sortFn: (a, b) => compare(b.content.nickName, a.content.nickName),
    },
    {
      label: 'nickname (desc)',
      value: AthleteGridSortByOption.DESC,
      sortFn: (a, b) => compare(a.content.nickName, b.content.nickName),
    },
    {
      label: AthleteGridSortByOption.POWER_HIGHT_TO_LOW,
      value: AthleteGridSortByOption.POWER_HIGHT_TO_LOW,
      sortFn: (a, b) => b.content.strength.values - a.content.strength.values,
    },
    {
      label: AthleteGridSortByOption.POWER_LOW_TO_HIGH,
      value: AthleteGridSortByOption.POWER_LOW_TO_HIGH,
      sortFn: (a, b) => a.content.strength.values - b.content.strength.values,
    },
  ],
};
