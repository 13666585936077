import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  softCurrency: {
    'display': 'flex',
    'justifyContent': 'center',
    '.softCurrency__content': {
      color: 'softCurrency.main',
      filter: 'drop-shadow(0px 4px 4px #9CA3AF)',
    },
  },
  premiumCurrency: {
    'display': 'flex',
    'alignItems': 'center',
    'borderRadius': '50px',
    'border': '2px solid',
    'borderColor': 'premiumCurrency.main',
    'paddingY': 1,
    'paddingX': 3,
    'backgroundColor': 'premiumCurrency.transparent',
    '.premiumCurrency__content': {
      color: 'premiumCurrency.main',
      filter: 'drop-shadow(0px 4px 4px #818CF8)',
    },
  },
};

export { styles as CurrencyBalanceStyles };
