import { AppRoute } from '@/_routes';
import { useAuthStore } from '@/lib/store/auth/auth.store';
import { useStyles } from '@/lib/styles/styles.hooks';
import { GlobeAltIcon } from '@heroicons/react/outline';
import { Avatar, AvatarProps, Box, Typography } from '@mui/material';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import IconContainer from '../IconContainer/IconContainer';
import { AvatarStyles } from './UserAvatar.styles';

export interface UserAvatarProps extends AvatarProps {
  showUsername?: boolean;
  color?: 'primary' | 'secondary' | 'accent' | 'transparent';
  image?: string;
  isOnCard?: boolean;
  fallbackIcon?: JSX.Element;
}

const UserAvatar: FC<UserAvatarProps> = ({
  color = 'accent',
  fallbackIcon,
  isOnCard = false,
  showUsername = true,
  image,
  ...rest
}) => {
  const session = useSession();

  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const styles = useStyles(AvatarStyles, { color: imageUrl ? 'transparent' : color, isOnCard });

  const { userProfile } = useAuthStore(
    (state) => ({
      userProfile: state.userProfile,
    }),
    shallow
  );

  const router = useRouter();

  const userName = session?.data?.user?.name ?? '';

  useEffect(() => {
    if (!userProfile?.avatarImageKey) {
      setImageUrl(undefined);
    } else {
      setImageUrl(process.env.NEXT_PUBLIC_S3_MEDIA_BUCKET_URL + userProfile.avatarImageKey);
    }
  }, [userProfile?.avatarImageKey]);

  const handleClick = () => {
    router.push(AppRoute.EDIT_PROFILE);
  };

  return (
    <Box sx={styles.root} className="UserAvatar-root" onClick={handleClick}>
      <Avatar sx={styles.avatar} src={image || imageUrl} alt={`${userName} profile picture`} {...rest}>
        {!imageUrl ? (
          userName.charAt(0)
        ) : (
          <IconContainer sx={styles.fallbackIcon} className="UserAvatar-fallbackIcon">
            {fallbackIcon ? <>{fallbackIcon}</> : <GlobeAltIcon />}
          </IconContainer>
        )}
      </Avatar>
      {showUsername && (
        <Typography sx={styles.userName} variant="h5" className="UserAvatar-userName">
          {isOnCard && '@'}
          {userName}
        </Typography>
      )}
    </Box>
  );
};

export default UserAvatar;
