import { ClickAwayListener, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { FC, useEffect, useState } from 'react';

import { AppRoute } from '@/_routes';
import UserAvatar from '@/components/atoms/UserAvatar/UserAvatar';
import { useLogout } from '@/hooks/useLogout';

import { useStyles } from '@/lib/styles/styles.hooks';
import { AppIcons } from '@/lib/utils/iconsLibrary';
import { theme } from '@/theme/theme';

import { useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';

import { NavigationLink } from '../../../others/Layout/Layout';
import AppBreadcrumbs from '../../AppBreadcrumbs/AppBreadcrumbs';
import DropdownItem from '../../DropdownItem/DropdownItem';
import DropdownList from '../../DropdownList/DropdownList';
import { TopNavigationStyles } from './TopNavigation.styles';

import CurrencyBalance from '@/components/atoms/CurrencyBalance/CurrencyBalance';
import Notification from '@/components/atoms/Notification/Notification';
import { useNotificationsStore } from '@/lib/store/notifications/notifications.store';
import { useUiStore } from '@/lib/store/ui/ui.store';
import NotificationStackList from '../../NotificationStackList/NotificationStackList';
import AppLink from '@/components/atoms/AppLink/AppLink';

interface TopNavigationProps {
  accountLinks?: NavigationLink[];
  disableNavigation?: boolean;
}

const TopNavigation: FC<TopNavigationProps> = ({ accountLinks, disableNavigation }) => {
  const styles = useStyles(TopNavigationStyles, { disableNavigation });
  const [isTopMenuOpen, setIsTopMenuOpen] = useState<boolean>(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState<boolean>();

  const session = useSession();

  const { inventoryNotification, setInventoryNotification } = useUiStore((state) => state);
  const { unreadCount, hasNotifications, clearNotifications } = useNotificationsStore((state) => state);

  const authenticated = session?.status === 'authenticated';

  const isMobile = useMediaQuery(theme.breakpoints.down('large'));

  const logout = useLogout();

  useEffect(() => {
    try {
      const _inventoryNotification = localStorage.getItem('inventoryNotification');
      if (_inventoryNotification) {
        setInventoryNotification(parseInt(_inventoryNotification));
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleOpenTopMenu = () => {
    setIsTopMenuOpen(!isTopMenuOpen);
  };

  async function handleSignOut() {
    await logout();
    setIsTopMenuOpen(false);
  }

  const handleOpenNotificationMenu = () => {
    if (hasNotifications) setIsNotificationMenuOpen(!isNotificationMenuOpen);
  };

  const handleClickAwayListener = (event: globalThis.MouseEvent | TouchEvent) => {
    event.preventDefault();
    setIsNotificationMenuOpen(false);
    clearNotifications();
  };

  return (
    <Box sx={styles.root} className="TopNavigation-root">
      <Box sx={styles.navbar} className="Navigation-navbar">
        <Box sx={styles.leftContainer} className="Navigation-leftContainer">
          <Box sx={styles.logo} className="Navigation-logo">
            <Link href={AppRoute.LANDING} passHref>
              <Image alt={'logo'} src={'/logo.png'} height="24" width="115" />
            </Link>
          </Box>
          {/*{!isMobile && !authenticated && (*/}
          {/*  <>*/}
          {/*    <Link href="/" passHref>*/}
          {/*      <Button variant={'text'}>Home</Button>*/}
          {/*    </Link>*/}
          {/*    <Link href="/" passHref>*/}
          {/*      <Button>Anchor</Button>*/}
          {/*    </Link>*/}
          {/*    <Link href="/" passHref>*/}
          {/*      <Button>Anchor</Button>*/}
          {/*    </Link>*/}
          {/*  </>*/}
          {/*)}*/}
        </Box>

        <Box sx={styles.rightContainer} className="Navigation-rightContainer">
          {!isMobile && !authenticated && (
            <>
              <Link href={'/auth/login/'} passHref>
                <Button>Sign in</Button>
              </Link>
              {/*<Link href={'/auth/registration/'} passHref>*/}
              {/*  <Button variant={'contained'}>Sign up</Button>*/}
              {/*</Link>*/}
              <AppLink
                sx={styles.discord}
                label={'Join Discord'}
                variant="text"
                textVariant="body"
                hasArrowIcon={false}
                route={'https://discord.com/invite/Mck87JSn'}
              />
            </>
          )}

          {authenticated && <CurrencyBalance />}

          {!isMobile && authenticated && (
            <>
              <Box position={'relative'}>
                <Link href={'/inventory/'} passHref>
                  <Button>{AppIcons.INVENTORY}</Button>
                </Link>
                {inventoryNotification > 0 && <Notification content={`${inventoryNotification}`} />}
              </Box>
              <Box position={'relative'}>
                <Box>
                  <Button
                    disabled={!hasNotifications}
                    onClick={handleOpenNotificationMenu}
                    sx={{ pointerEvents: isNotificationMenuOpen ? 'none' : 'all' }}
                  >
                    {AppIcons['BELL']}
                  </Button>
                  {unreadCount > 0 && <Notification content={unreadCount.toString()} />}
                </Box>
              </Box>
              <UserAvatar />
              <Button onClick={handleOpenTopMenu}>{AppIcons['CHEVRONDOWN']}</Button>
            </>
          )}

          {isMobile && <Button>{AppIcons['SEARCH']}</Button>}
        </Box>
      </Box>

      <AppBreadcrumbs />

      {!isMobile && isTopMenuOpen && accountLinks && (
        <>
          <Box sx={styles.menuCloseWrapper} onClick={() => setIsTopMenuOpen(false)} />
          <DropdownList sx={styles.dropDownMenu}>
            {accountLinks.map((menuItem, id) => (
              <DropdownItem
                name={menuItem.label}
                key={`DropdownNavigationItem-${id}`}
                label={menuItem.label}
                route={menuItem.href}
                index={id}
              />
            ))}
            {authenticated && <DropdownItem name="logout" label="Logout" onClick={handleSignOut} />}
          </DropdownList>
        </>
      )}

      {!isMobile && isNotificationMenuOpen && (
        <ClickAwayListener onClickAway={(event) => handleClickAwayListener(event)}>
          <Box sx={styles.notificationContainer}>
            <NotificationStackList />
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default TopNavigation;
