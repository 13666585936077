import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';

interface CheckboxStyleProps extends StyleStates {
  color: 'primary' | 'secondary' | 'accent';
}

const styles: ComponentStylesWithFns<CheckboxStyleProps> = {
  root: ({ color }) => [
    {
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      border: '1px solid',
      borderRadius: '4px',
    },
    color === 'primary' && {
      'backgroundColor': 'bgPrimary.primary',
      'borderColor': 'bgPrimary.primary',
      '&:hover': {
        borderColor: 'contentPrimary.primary',
      },
      '&.Mui-disabled': {
        borderColor: 'bgPrimary.primary',
      },
      '&.Mui-checked': {
        backgroundColor: 'contentPrimary.primary',
        borderColor: 'contentPrimary.primary',
      },
    },
    color === 'secondary' && {
      'backgroundColor': 'bgPrimary.secondary',
      'borderColor': 'bgPrimary.secondary',
      '&:hover': {
        borderColor: 'contentPrimary.secondary',
      },
      '&.Mui-disabled': {
        borderColor: 'bgPrimary.secondary',
      },
      '&.Mui-checked': {
        backgroundColor: 'contentPrimary.secondary',
        borderColor: 'contentPrimary.secondary',
      },
    },
    color === 'accent' && {
      'backgroundColor': 'bgPrimary.secondary',
      'borderColor': 'bgPrimary.secondary',
      '&:hover': {
        borderColor: 'contentSecondary.tertiary',
      },
      '&.Mui-disabled': {
        borderColor: 'bgPrimary.secondary',
      },
      '&.Mui-checked': {
        backgroundColor: 'accent.main',
        borderColor: 'accent.main',
      },
    },
  ],
  checkbox: {
    width: '16px',
    height: '16px',
    borderRadius: '4px',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
};

export { styles as SimpleCheckboxStyles };
