import AppImage from '@/components/atoms/AppImage/AppImage';
import RarityTag from '@/components/atoms/CardAtoms/RarityTag/RarityTag';
import StatusTag from '@/components/atoms/CardAtoms/StatusTag/StatusTag';
import CsRoleIcon from '@/components/atoms/Icons/CsRoleIcon/CsRoleIcon';
import { useGameAssetsStore } from '@/lib/store/gameAssets/game-assets.store';
import { useStyles } from '@/lib/styles/styles.hooks';
import { Button, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { AppAthleteCardStyles } from './AppAthleteCard.styles';
import { AthleteCard } from '@cstactics/types';

export enum AppAthleteCardStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}

interface AppAthleteCardProps {
  /** athlete card */
  athleteCard: AthleteCard;

  /** action */
  action?: {
    variant?: 'text' | 'tab' | 'contained' | 'outlined';
    label: string;
    disabled?: boolean;
    fn: (athleteCardId: string) => void | Promise<void>;
  };

  /** styles */
  sx?: SxProps<Theme>;
}

const AppAthleteCard: FC<AppAthleteCardProps> = ({ athleteCard, action, sx }) => {
  const [status, setStatus] = useState<AppAthleteCardStatus>();
  const [actionInProgress, setActionInProgress] = useState<boolean>();

  const styles = useStyles(AppAthleteCardStyles, { status }, sx);

  /** STORE */

  const { team, isAthleteInTeam, isAthleteCardBlocked, isAthleteInTeamRoster } = useGameAssetsStore(
    (state) => ({
      // states
      team: state.team,

      // actions
      isAthleteInTeam: state.isAthleteInTeam,
      isAthleteInTeamRoster: state.isAthleteInTeamRoster,
      isAthleteCardBlocked: state.isAthleteCardBlocked,
    }),
    shallow
  );

  const getImagePath = () => process.env.NEXT_PUBLIC_S3_MEDIA_BUCKET_URL + 'renders/' + athleteCard.image.key;
  const router = useRouter();

  const onActionButtonClick = async () => {
    setActionInProgress(true); 
    await action?.fn(athleteCard.uuid);
    setActionInProgress(false);
  };

  useEffect(() => {
    if (isAthleteInTeam(athleteCard.uuid)) {
      setStatus(AppAthleteCardStatus.ACTIVE);
    } else if (isAthleteCardBlocked(athleteCard)) {
      setStatus(AppAthleteCardStatus.BLOCKED);
    } else {
      setStatus(AppAthleteCardStatus.INACTIVE);
    }
  }, [athleteCard, team]);

  const handleClick = () => {
    router.push(`athlete-hub/card/${athleteCard.uuid}`);
  };

  return (
    <Box className="AppAthleteCard-root" sx={styles.root}>
      <Box sx={styles.topContainer} className="AppAthleteCard-topContainer">
        {/* {isCard(item) && (
          <Box sx={styles.avatarContainer}>
            <UserAvatar isOnCard={true} sx={styles.avatar} />
          </Box>
        )} */}

        <Box className="AppAthleteCard-cardImageContainer" sx={styles.cardImageContainer} onClick={handleClick}>
          <AppImage src={getImagePath()} alt="alt" ratioWidth={4} ratioHeight={5} />

          {status && <StatusTag athleteCard={athleteCard} status={status} sx={styles.statusTag} />}

          <Box className="AppAthleteCard-cardTextContainer" sx={styles.cardTextContainer}>
            <Box className="AppAthleteCard-tagAndRole" sx={styles.tagAndRole}>
              <RarityTag rarity={athleteCard.rarity} />
              <Box className="AppAthleteCard-roleIcon" display={'flex'} sx={styles.roleIcon}>
                <CsRoleIcon
                  sx={{
                    color: 'black',
                  }}
                  role={athleteCard.content.proficiencies.roles.primary}
                />
                <Typography sx={styles.roleIconLabel} marginLeft={2}>
                  {athleteCard.content.proficiencies.roles.primary}
                </Typography>
              </Box>
            </Box>
            <Typography variant="h3">{athleteCard.content.nickName}</Typography>
            <Typography variant="caption">Athlete</Typography>
          </Box>
        </Box>
      </Box>
      <Box className="AppAthleteCard-bottomContainer" sx={styles.bottomContainer}>
        <Box className="AppAthleteCard-bottomInfo" sx={styles.bottomInfo}>
          <Box sx={styles.bottomContainerElement}>
            <Typography sx={styles.bottomValue} variant="h4">
              {Math.round(athleteCard.content.strength.values)}
            </Typography>
            <Typography sx={styles.bottomLabel} variant="body">
              Power
            </Typography>
          </Box>

          <Box sx={styles.bottomContainerElement}>
            <Typography sx={styles.rarity} variant="h4">
              {Math.round(athleteCard.content.strength.talents)}
            </Typography>
            <Typography sx={styles.bottomLabel} variant="body">
              Talent
            </Typography>
          </Box>
        </Box>
        {action && (
          <Box className="AppAthleteCard-action" sx={styles.action}>
            {isAthleteInTeamRoster(athleteCard.uuid) ? (
              <Tooltip arrow={true} title={'Before firing an athlete you have to bench him first.'} placement={'top'}>
                <Box>
                  <Button
                    variant={action.variant || 'contained'}
                    color="secondary"
                    onClick={onActionButtonClick}
                    disabled={action.disabled || actionInProgress || isAthleteCardBlocked(athleteCard)}
                    fullWidth
                  >
                    {action.label}
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <Button
                variant={action.variant || 'contained'}
                color="secondary"
                onClick={onActionButtonClick}
                disabled={action.disabled || actionInProgress || isAthleteCardBlocked(athleteCard)}
                fullWidth
              >
                {action.label}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AppAthleteCard;
