import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    minWidth: '22px',
    height: '22px',
    fontSize: '11px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'error.light',
    top: 2,
    right: -7,
    backgroundColor: 'error.dark',
    borderRadius: 3,
    paddingX: '2px',
  },
};

export { styles as NotificationStyles };
