import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    width: [342, 568, 414, 564],
    height: 'content-fit',
    maxHeight: ['calc(100vh - 192px)', 'calc(100vh - 208px)', 'calc(100vh - 132px)', 'calc(100vh - 132px)'],
    position: 'fixed',
    backgroundColor: 'bgPrimary.primary',

    top: [60, 68, 84, 84],
    marginTop: 4,
    boxSizing: 'border-box',
    overflowY: 'auto',
    zIndex: 1000,

    alignSelf: ['center', 'center', 'end', 'end'],
    display: 'flex',
    flexDirection: 'column',

    borderRadius: [1],
    border: '1px solid',
    borderColor: 'contentSecondary.tertiary',

    marginLeft: [0, 0, 'calc(24px + 414px)', 'calc(24px + 564px)'],
  },
};

export { styles as NotificationStackListStyles };
