import { Box, Radio, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { SimpleRadioStyles } from './SimpleRadio.styles';

interface SimpleRadioProps {
  color?: 'primary' | 'secondary' | 'accent';
  value?: string;
  required?: boolean;
  checked?: boolean;
  name?: string;
  sx?: SxProps<Theme>;
}

const SimpleRadio: FC<SimpleRadioProps> = ({ color = 'accent', value, required = false, checked, sx }) => {
  const styles = useStyles(SimpleRadioStyles, { color }, sx);

  return (
    <Radio
      sx={styles.root}
      value={value}
      required={required}
      checked={checked}
      icon={<Box></Box>}
      checkedIcon={<Box sx={styles.checked} className="AppRadioButton-checkIcon"></Box>}
    />
  );
};

export default SimpleRadio;
