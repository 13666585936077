import { AthleteCard, csRoles } from '@cstactics/types';
import {
  ItemFilterBarFilter,
  ItemFilterBarSortByOptions,
} from '@/components/molecules/ItemFilterBar/ItemFilterBar.types';

export const teamBenchFilterState: ItemFilterBarFilter<AthleteCard>[] = [
  {
    name: 'search',
    label: 'Looking for Athlete?',
    type: 'input',
    value: '',
    filterFn: (item, value) => {
      return (
        item.content.nickName.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        item.content.firstName.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        item.content.lastName.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    },
  },
  {
    name: 'role',
    label: 'Role',
    type: 'select',
    value: 'ALL',
    filterFn: (item, value) => item.content.proficiencies.roles.primary === value,
    options: csRoles.map((role) => ({ value: role, label: role })),
  },
];

enum BenchGridSortByOption {
  ASC = 'asc',
  DESC = 'desc',
  POWER_HIGHT_TO_LOW = 'power hight to low',
  POWER_LOW_TO_HIGH = 'power low to high',
  TALENT_HIGHT_TO_LOW = 'talent hight to low',
  TALENT_LOW_TO_HIGH = 'talent low to high',
}

const { compare } = new Intl.Collator();

export const teamBenchSortByOptions: ItemFilterBarSortByOptions<AthleteCard> = {
  label: 'Sort By',
  options: [
    {
      label: 'nickname (asc)',
      value: BenchGridSortByOption.ASC,
      sortFn: (a, b) => compare(b.content.nickName, a.content.nickName),
    },
    {
      label: 'nickname (desc)',
      value: BenchGridSortByOption.DESC,
      sortFn: (a, b) => compare(a.content.nickName, b.content.nickName),
    },
    {
      label: BenchGridSortByOption.POWER_HIGHT_TO_LOW,
      value: BenchGridSortByOption.POWER_HIGHT_TO_LOW,
      sortFn: (a, b) => b.content.strength.values - a.content.strength.values,
    },
    {
      label: BenchGridSortByOption.POWER_LOW_TO_HIGH,
      value: BenchGridSortByOption.POWER_LOW_TO_HIGH,
      sortFn: (a, b) => a.content.strength.values - b.content.strength.values,
    },
    {
      label: BenchGridSortByOption.TALENT_HIGHT_TO_LOW,
      value: BenchGridSortByOption.TALENT_HIGHT_TO_LOW,
      sortFn: (a, b) => b.content.strength.values - a.content.strength.talents,
    },
    {
      label: BenchGridSortByOption.TALENT_LOW_TO_HIGH,
      value: BenchGridSortByOption.TALENT_LOW_TO_HIGH,
      sortFn: (a, b) => a.content.strength.values - b.content.strength.talents,
    },
  ],
};
