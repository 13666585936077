import { Box, Typography } from '@mui/material';
import { useStyles } from '@/lib/styles/styles.hooks';
import { CurrencyBalanceStyles } from './CurrencyBalance.styles';
import { useEffect } from 'react';
import { AppIcons } from '@/lib/utils/iconsLibrary';
import PremiumCurrency from '@/public/icons/premium-currency.svg';
import { useEconomyStore } from '@/lib/store/economy/economy.store';

const CurrencyBalance = () => {
  const styles = useStyles(CurrencyBalanceStyles, {});
  const balance = useEconomyStore((state) => state.balance);

  useEffect(() => {
    fetch('/api/user/economy')
      .then((data) => data.json())
      .then((data) => {
        if (!data.message) {
          useEconomyStore.setState({
            balance: {
              premiumCurrency: data.premiumCurrency,
              softCurrency: data.softCurrency,
            },
          });
        }
      });
  }, []);

  return (
    <>
      <Box sx={styles.softCurrency}>
        <Box width={22} marginRight={2} display={'flex'} alignItems={'center'}>
          {AppIcons.SOFT_CURRENCY}
        </Box>
        <Typography className={'softCurrency__content'}>{balance.softCurrency}</Typography>
      </Box>
      <Box sx={styles.premiumCurrency}>
        <Box width={22} marginRight={2} display={'flex'} alignItems={'center'}>
          <PremiumCurrency />
        </Box>
        <Typography className={'premiumCurrency__content'}>{balance.premiumCurrency}</Typography>
      </Box>
    </>
  );
};

export default CurrencyBalance;
