import { create } from 'zustand';
import { withDevtools } from '../utils';
import { BalanceSlice, createBalanceSlice } from './balance/balance.slice';

export type EconomyState = BalanceSlice;

export const useEconomyStore = create<EconomyState>()(
  withDevtools((set, get, ...a) => ({
    ...createBalanceSlice(set, get, ...a),
  }))
);
