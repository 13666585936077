import { AppAthleteCardStatus } from '@/components/molecules/CardMolecules/AppAthleteCard/AppAthleteCard';
import { StaffCardStatus } from '@/components/molecules/StaffMolecules/AppStaffCard/AppStaffCard';
import { useGameAssetsStore } from '@/lib/store/gameAssets/game-assets.store';
import { useStyles } from '@/lib/styles/styles.hooks';
import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { AthleteCard } from '@cstactics/types';
import { FC, useEffect, useState } from 'react';
import { StatusTagStyles } from './StatusTag.styles';

interface StatusTagProps {
  /** athlete */
  athleteCard: AthleteCard;

  /** status */
  status: AppAthleteCardStatus | StaffCardStatus;

  /** styles */
  sx?: SxProps<Theme>;
}

const StatusTag: FC<StatusTagProps> = ({ athleteCard, status, sx }) => {
  const [label, setLabel] = useState<string>();

  const styles = useStyles(StatusTagStyles, { status }, sx);
  const getAthleteBlockedDistance = useGameAssetsStore((state) => state.getAthleteBlockedDistance);

  useEffect(() => {
    if (status === AppAthleteCardStatus.BLOCKED) {
      setLabel(`Available in ${getAthleteBlockedDistance(athleteCard)}`);
      return;
    } else if (status === AppAthleteCardStatus.INACTIVE) {
      setLabel('Available');
      return;
    } else {
      setLabel('Hired');
      return;
    }
  }, [status]);

  return (
    <Box className="StatusTag-root" sx={styles.root}>
      <Box className="StatusTag-glow" sx={styles.glow}>
        <Box className="StatusTag-dot" sx={styles.dot} />
        <Typography variant="caption" className="TeamStatusTag-text" sx={styles.text}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatusTag;
