import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns<{ topOffset: number; leftOffset: number }> = {
  root: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    overflow: 'hidden',
  },
  shapeSmall: ({ topOffset, leftOffset }) => [
    {
      width: 216,
      height: 200,
      position: 'absolute',
      left: 200 + leftOffset,
      top: 200 + topOffset,
      backgroundColor: '#2563EB',
      mixBlendMode: 'normal',
      opacity: 0.6,
      filter: 'blur(100px)',
      borderRadius: '139.947px',
      transform: 'matrix(0.94, 0.34, -0.34, 0.94, 0, 0)',
    },
  ],
  shapeMedium: ({ topOffset, leftOffset }) => [
    {
      width: 392,
      height: 355,
      position: 'absolute',
      left: 44 + leftOffset,
      top: 35 + topOffset,
      backgroundColor: '#2563EB',
      mixBlendMode: 'normal',
      opacity: 0.4,
      filter: 'blur(128px)',
      transform: 'matrix(0.53, 0.85, -0.86, 0.51, 0, 0)',
    },
  ],
  shapeBig: ({ topOffset, leftOffset }) => [
    {
      width: 667,
      height: 603,
      position: 'absolute',
      left: -50 + leftOffset,
      top: -120 + topOffset,
      backgroundColor: '#2563EB',
      mixBlendMode: 'normal',
      opacity: 0.2,
      filter: 'blur(150px)',
      transform: 'matrix(-0.43, 0.9, -0.89, -0.45, 0, 0)',
    },
  ],
};

export { styles as LightBulbStyles };
