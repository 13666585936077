import { ComponentStylesWithFns } from '@/lib/styles/styles.types';
import { AppAthleteCardStatus } from '@/components/molecules/CardMolecules/AppAthleteCard/AppAthleteCard';

const styles: ComponentStylesWithFns<{ status?: AppAthleteCardStatus }> = {
  root: {},
  glow: ({ status }) => [
    {
      paddingY: 1,
      paddingLeft: '12px',
      paddingRight: 2,
      backdropFilter: 'blur(16px)',
      borderRadius: '16px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      gap: '12px',
    },
    status === AppAthleteCardStatus.ACTIVE && {
      backgroundColor: 'success.transparent',
    },
    status === AppAthleteCardStatus.BLOCKED && {
      backgroundColor: 'warning.transparent',
    },
    status === AppAthleteCardStatus.INACTIVE && {
      backgroundColor: 'error.transparent',
    },
  ],
  dot: ({ status }) => [
    {
      height: 8,
      width: 8,
      borderRadius: '50%',
    },
    status === AppAthleteCardStatus.INACTIVE && {
      backgroundColor: 'success.main',
    },
    status === AppAthleteCardStatus.BLOCKED && {
      backgroundColor: 'warning.main',
    },
    status === AppAthleteCardStatus.ACTIVE && {
      backgroundColor: 'error.main',
    },
  ],
  text: ({ status }) => [
    {
      lineHeight: '',
    },
    status === AppAthleteCardStatus.INACTIVE && {
      color: 'success.main',
    },
    status === AppAthleteCardStatus.BLOCKED && {
      color: 'warning.main',
    },
    status === AppAthleteCardStatus.ACTIVE && {
      color: 'error.main',
    },
  ],
};

export { styles as StatusTagStyles };
