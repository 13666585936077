import {
  Card,
  isAthleteCard,
  isPremiumCurrencyCard,
  isSoftCurrencyCard,
  isSponsorshipContractCard,
  isStaffCard,
} from '@cstactics/types';

export function getCardTitle(card: Card): string {
  if (isAthleteCard(card) || isStaffCard(card)) {
    const { nickName, firstName, lastName } = card.content;
    return `${nickName} (${firstName} ${lastName})`;
  } else if ((isSoftCurrencyCard(card), isPremiumCurrencyCard(card))) {
    const { name } = card.content;
    return name;
  } else if (isSponsorshipContractCard(card)) {
    const { name } = card.content;
    return name;
  } else {
    return 'Unknown card type';
  }
}
