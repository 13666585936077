import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    position: 'relative',
    width: '100%',
    height: '1px',
    backgroundColor: 'contentPrimary.tertiary',
    margin: '16px 0px 19px 0px',
  },
};

export { styles as LineStyles };
