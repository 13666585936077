import { StateCreator } from 'zustand';
import { EconomyState } from '../economy.store';

// TODO fix type
export interface Balance {
  softCurrency: number;
  premiumCurrency: number;
}

export interface BalanceSlice {
  // states
  balance: Balance;
  // actions
}

export const createBalanceSlice: StateCreator<EconomyState, [], [], BalanceSlice> = () => ({
  /** states */
  balance: { softCurrency: 0, premiumCurrency: 0 },
});
