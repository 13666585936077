import AppNotification from '@/components/atoms/AppNotification/AppNotification';
import { useNotificationsStore } from '@/lib/store/notifications/notifications.store';
import { useStyles } from '@/lib/styles/styles.hooks';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { NotificationStackListStyles } from './NotificationStackList.styles';

interface NotificationStackListProps {
  /** styles */
  sx?: SxProps<Theme>;
}

const NotificationStackList: FC<NotificationStackListProps> = ({ sx }) => {
  const styles = useStyles(NotificationStackListStyles, {}, sx);

  const { notifications } = useNotificationsStore((state) => state);

  return (
    <Box sx={styles.root} className="NotificationStackList-root">
      {notifications.map((notification, id) => (
        <AppNotification key={id} isLast={id === notifications.length - 1} notification={notification} />
      ))}
    </Box>
  );
};

export default NotificationStackList;
