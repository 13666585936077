import { AppRoute } from '@/_routes';
import LightBulb from '@/components/atoms/LightBulb/LightBulb';
import Footer from '@/components/blocks/Footer/Footer';
import BottomNavigation from '@/components/molecules/Navigations/BottomNavigation/BottomNavigation';
import SideNavigation from '@/components/molecules/Navigations/SideNavigation/SideNavigation';
import TopNavigation from '@/components/molecules/Navigations/TopNavigation/TopNavigation';
import SystemBanner from '@/components/molecules/SystemBanner/SystemBanner';
import { useGameAssetsStore } from '@/lib/store/gameAssets/game-assets.store';
import { StrapiLayout } from '@/lib/strapi/strapi.types';
import { useStyles } from '@/lib/styles/styles.hooks';
import { AppIcons } from '@/lib/utils/iconsLibrary';
import { theme } from '@/theme/theme';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { RankInProgressState } from '@cstactics/types';
import { useSession } from 'next-auth/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { LayoutStyles } from './Layout.styles';

interface LayoutProps {
  layoutData: StrapiLayout;
  showSideNavigation?: boolean;
  showFooter?: boolean;
  lightBulbBg?: boolean;
  children?: ReactNode;
  disableNavigation?: boolean;
}

type NavSection = 'account' | 'menu' | 'bottom' | 'side' | 'unauthorized';
export interface NavigationLink {
  id: number;
  /** label */
  label?: string;
  /** label */
  href: string;
  /** icon element */
  icon?: JSX.Element;
  /** navigation section */
  navSection?: NavSection[];
}

const Layout: FC<LayoutProps> = ({
  layoutData,
  lightBulbBg = false,
  showSideNavigation = true,
  children,
  showFooter = true,
  disableNavigation = false,
}) => {
  const styles = useStyles(LayoutStyles, {});
  const [accountLinks, setAccountLinks] = useState<NavigationLink[]>();
  const [bottomNavigationLinks, setBottomNavigationLinks] = useState<NavigationLink[]>();
  const [menuNavigationLinks, setMenuNavigationLinks] = useState<NavigationLink[]>();
  const [sideNavigationLinks, setSideNavigationLinks] = useState<NavigationLink[]>();

  const isMobile = useMediaQuery(theme.breakpoints.down('large'));

  const session = useSession();

  const team = useGameAssetsStore((state) => state.team);

  //
  // will move into Strapi
  //
  const unAuthorizedLinks: NavigationLink[] = [
    { id: 1, label: 'Home', href: AppRoute.LANDING, navSection: ['menu'] },
    { id: 1, label: 'Help & Getting Started', href: AppRoute.HELP, navSection: ['menu'] },
    { id: 2, label: 'Sign In', href: AppRoute.LOGIN, navSection: [] },
    { id: 2, label: 'Sign Up', href: AppRoute.REGISTRATION, navSection: [] },
  ];

  const authorizedLinks: NavigationLink[] = [
    { id: 1, label: 'Edit Profile', href: AppRoute.EDIT_PROFILE, navSection: ['account'] },
    { id: 3, label: 'Change Email', href: AppRoute.CHANGE_EMAIL, navSection: ['account'] },
    { id: 4, label: 'Change Password', href: AppRoute.CHANGE_PASSWORD, navSection: ['account'] },
    { id: 5, label: 'Transaction History', href: AppRoute.HISTORY_TRANSACTION, navSection: ['account'] },
    { id: 6, label: 'Trading History', href: AppRoute.LANDING },
    { id: 7, label: 'Marketing Preferences', href: AppRoute.MARKETING_PREFERENCES, navSection: ['account'] },
    { id: 8, label: 'Privacy Settings', href: AppRoute.PRIVACY_SETTINGS, navSection: ['account'] },
    { id: 9, label: 'Dashboard', href: AppRoute.DASHBOARD, icon: AppIcons.DASHBOARD, navSection: ['bottom', 'side'] },
    {
      id: 10,
      label: 'Athletes Hub',
      href: AppRoute.ATHLETE_HUB,
      icon: AppIcons.ATHLETES_HUB,
      navSection: ['bottom', 'side'],
    },
    { id: 11, label: 'Team', href: AppRoute.TEAM, icon: AppIcons.TEAM, navSection: ['menu', 'side'] },
    {
      id: 15,
      label: 'Tactics',
      href: AppRoute.TACTICS,
      icon: AppIcons.TACTICS,
      navSection: ['side'],
    },
    {
      id: 15,
      label: 'Training',
      href: AppRoute.TRAINING,
      icon: AppIcons.TRAINING,
      navSection: ['bottom', 'side'],
    },
    { id: 12, label: 'Facility', href: AppRoute.FACILITY, icon: AppIcons.FACILITY, navSection: ['menu', 'side'] },
    {
      id: 14,
      label: 'League',
      href:
        team?.ladderInfos.main.rankIn === RankInProgressState.IN_PROGRESS
          ? AppRoute.LEAGUE_QUALIFIERS
          : AppRoute.LEAGUE,
      icon: AppIcons.TROPHY,
      navSection: ['bottom', 'side'],
    },
    {
      id: 16,
      label: 'Economy',
      href: AppRoute.ECONOMY,
      icon: AppIcons.BANK,
      navSection: ['side', 'menu'],
    },
    { id: 18, label: 'Shop', href: AppRoute.SHOP, icon: AppIcons.SHOP, navSection: ['menu', 'side'] },
    {
      id: 21,
      label: 'Reedem Voucher',
      href: AppRoute.REDEEM_VOUCHER,
      icon: AppIcons.VOUCHER,
      navSection: ['menu'],
    },
    {
      id: 19,
      label: 'Help & Getting Started',
      href: AppRoute.HELP,
      icon: AppIcons.QUESTION_MARK_CIRCLE,
      navSection: ['menu', 'unauthorized'],
    },
  ];

  useEffect(() => {
    if (!authorizedLinks) return;

    // handle unauthorized state
    if (session.status !== 'authenticated') {
      setAccountLinks(unAuthorizedLinks);
      setMenuNavigationLinks(unAuthorizedLinks.filter((item) => item.navSection?.includes('menu')));
      setBottomNavigationLinks(authorizedLinks.filter((item) => item.navSection?.includes('bottom')));
    }
    // handle authorized state
    else if (session.status === 'authenticated') {
      setAccountLinks(authorizedLinks.filter((item) => item.navSection?.includes('account')));
      setBottomNavigationLinks(authorizedLinks.filter((item) => item.navSection?.includes('bottom')));
      setMenuNavigationLinks(authorizedLinks.filter((item) => item.navSection?.includes('menu')));
      setSideNavigationLinks(authorizedLinks.filter((item) => item.navSection?.includes('side')));
    }
  }, [session.status]);

  return (
    <Box className="LayoutWrapper" sx={styles.layoutWrapper}>
      {lightBulbBg && (
        <>
          <LightBulb />
          <LightBulb leftOffset={1200} topOffset={200} />
        </>
      )}

      <TopNavigation accountLinks={accountLinks} disableNavigation={disableNavigation} />
      {isMobile && !disableNavigation && (
        <BottomNavigation
          accountLinks={accountLinks}
          bottomNavigationLinks={bottomNavigationLinks}
          menuNavigationLinks={menuNavigationLinks}
        />
      )}

      <Box className="Layout-root" sx={styles.root}>
        {!isMobile && session.status === 'authenticated' && showSideNavigation && (
          <SideNavigation sideNavigationLinks={sideNavigationLinks} disableNavigation={disableNavigation} />
        )}
        <Box
          className="Layout-container"
          sx={
            session.status === 'authenticated' && showSideNavigation
              ? {
                  ...styles.container,
                  width: ['100%', '100%', 'calc(100% - 84px)', 'calc(100% - 270px)'],
                  overflow: ['auto', 'auto', disableNavigation ? 'hidden' : 'auto'],
                }
              : { ...styles.container, width: '100%', marginLeft: 0 }
          }
        >
          <Box sx={styles.main} className="Layout-main">
            {layoutData?.systemBanner?.label && (
              <SystemBanner label={layoutData.systemBanner.label} severity={layoutData.systemBanner.severity} />
            )}
            <Box sx={styles.mainContent} className="Layout-mainContent" component="main">
              {children}
            </Box>
          </Box>

          {layoutData?.footerData && showFooter && <Footer footerData={layoutData.footerData} />}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
