import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';

interface AvatarStyleProps extends StyleStates {
  color: 'primary' | 'secondary' | 'accent' | 'transparent';
  isOnCard: boolean;
}

const styles: ComponentStylesWithFns<AvatarStyleProps> = {
  root: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  avatar: ({ color }) => [
    {
      marginRight: [3, 0],
      boxSizing: 'border-box',
    },
    color === 'primary' && {
      backgroundColor: 'bgPrimary.primary',
      color: 'contentPrimary.primary',
    },
    color === 'secondary' && {
      backgroundColor: 'bgSecondary.primary',
      color: 'contentSecondary.primary',
    },
    color === 'accent' && {
      backgroundColor: 'accent.main',
      color: 'contentSecondary.primary',
    },
    color === 'transparent' && {
      backgroundColor: 'transparent',
    },
  ],
  shortName: {
    width: 52,
    lineHeight: '52px',
    textAlign: 'center',
  },
  userName: ({ isOnCard }) => [
    {
      paddingLeft: 3,
    },
    isOnCard && {
      display: 'block',
    },
    !isOnCard && {
      display: {
        small: 'none',
        medium: 'block',
      },
    },
  ],
  verifiedIcon: {
    display: {
      small: 'none',
      medium: 'block',
    },
  },
  fallbackIcon: {
    height: '24px',
    width: '24px',
    svg: {
      height: '24px',
      width: '24px',
    },
  },
  badge: {
    color: 'contentPrimary.primary',
  },
};

export { styles as AvatarStyles };
