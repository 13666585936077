import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';

interface DropdownItemStyleProps extends StyleStates {
  disabled?: boolean;
}

const styles: ComponentStylesWithFns<DropdownItemStyleProps> = {
  root: ({ disabled }) => [
    {
      'borderRadius': '4px',
      'width': '100%',
      'padding': '14px 16px 14px 20px',
      'boxSizing': 'border-box',
      'cursor': 'pointer',
      'color': 'contentPrimary.primary',

      '&:hover': {
        backgroundColor: 'secondary.transparent',
      },
      '&:active': {
        'backgroundColor': 'contentPrimary.primary',
        'color': 'contentSecondary.primary',
        '.DropdownIcon-iconContainer': {
          color: 'contentSecondary.primary',
        },
      },
      '& .MuiListItemIcon-root': {
        minWidth: 0,
      },
      '& .MuiListItemButton-root': {
        'padding': 0,
        'display': 'flex',
        'gap': '16px',
        '& .MuiTouchRipple-root': {
          display: 'none',
        },
        '&:active': {
          backgroundColor: 'inherit',
          color: 'inherit',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .Mui-disabled': {
        'color': 'contentPrimary.disabled',
        'opacity': 1,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    disabled == true && {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  ],
  dropdownIconContainer: {
    color: 'contentPrimary.primary',
  },
};

export { styles as DropdownItemStyles };
