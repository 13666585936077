import AppLink from '@/components/atoms/AppLink/AppLink';
import Line from '@/components/atoms/Line/Line';
import { StrapiFooter } from '@/lib/strapi/strapi.types';
import { useStyles } from '@/lib/styles/styles.hooks';
import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Image from 'next/legacy/image';
import { FC } from 'react';
import { FooterStyles } from './Footer.styles';

interface FooterProps {
  /** footer content object */
  footerData?: StrapiFooter;
  /** style */
  sx?: SxProps<Theme>;
}

const Footer: FC<FooterProps> = ({ footerData, sx }) => {
  const styles = useStyles(FooterStyles, {}, sx);

  return (
    <Box component="footer" sx={styles.root} className="Footer-root">
      <Box sx={styles.container}>
        <Box sx={styles.topContainer}>
          <Box sx={styles.logoContainer} className="Footer-logoContainer">
            <Box sx={styles.logo} className="Footer-logo">
              <Image src="/logo.png" layout="fill" objectFit="cover" alt="logo" className="Footer-image" />
            </Box>
            <Typography variant="body">{footerData?.infoText}</Typography>
          </Box>
          <Box sx={styles.linksContainer}>
            <Typography sx={styles.title} variant="h4">
              {footerData?.navigateTitle}
            </Typography>
            {footerData?.navigateLinks?.map((link) => (
              <AppLink
                key={`FooterLink-${link?.id}`}
                hasArrowIcon={link.hasArrowIcon}
                label={link.label}
                variant={link.variant}
                href={link.href}
                color={link.color || 'secondary'}
              />
            ))}
          </Box>
          <Box sx={styles.linksContainer}>
            <Typography sx={styles.title} variant="h4">
              {footerData?.learnMoreTitle}
            </Typography>
            {footerData?.learnMoreLinks?.map((link) => (
              <AppLink
                key={`FooterLink-${link?.id}`}
                hasArrowIcon={link.hasArrowIcon}
                label={link.label}
                variant={link.variant}
                href={link.href}
                color={link.color || 'secondary'}
              />
            ))}
          </Box>
          <Box sx={styles.linksContainer}>
            <Typography sx={styles.title} variant="h4">
              {footerData?.socialMediaTitle}
            </Typography>
            {footerData?.socialMediaLinks?.map((link) => (
              <AppLink
                key={`FooterLink-${link?.id}`}
                hasArrowIcon={link.hasArrowIcon}
                label={link.label}
                variant={link.variant}
                href={link.href}
                color={link.color || 'secondary'}
              />
            ))}
          </Box>
        </Box>
        <Line sx={styles.line} />
        <Box sx={styles.bottomContainer}>
          <Box>
            <Typography sx={styles.copyright} variant="body">
              © CSTactics 2022 all rights reserved.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
