import { BanIcon, CheckIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { Alert, AlertProps, Collapse, SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useState } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import IconContainer from '@/components/atoms/IconContainer/IconContainer';
import { SystemBannerStyles } from './SystemBanner.styles';

interface SystemBannerProps extends AlertProps {
  label?: string;
  /** styles */
  sx?: SxProps<Theme>;
}

const SystemBanner: FC<SystemBannerProps> = ({ label, sx, ...props }) => {
  const styles = useStyles(SystemBannerStyles, {}, sx);
  const [open, setOpen] = useState(true);

  return (
    <Box className="SystemBanner-root" sx={styles.root}>
      <Collapse in={open}>
        <Box sx={styles.bannerContainer}>
          <Alert
            action={
              <Box
                sx={styles.closingButton}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <IconContainer>
                  <XIcon />
                </IconContainer>
              </Box>
            }
            iconMapping={{
              success: (
                <IconContainer>
                  <CheckIcon />
                </IconContainer>
              ),
              error: (
                <IconContainer>
                  <BanIcon />
                </IconContainer>
              ),
              warning: (
                <IconContainer>
                  <ExclamationIcon />
                </IconContainer>
              ),
              info: (
                <IconContainer>
                  <CheckIcon />
                </IconContainer>
              ),
            }}
            {...props}
          >
            <Typography variant="h5">{label}</Typography>
          </Alert>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SystemBanner;
