import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    'position': 'fixed',
    'width': [0, 0, 84, 270],
    'top': 84,
    'overflowY': ['auto', 'auto', 'scroll', 'auto'],
    'height': 'calc(100vh - 84px)',
    'backgroundColor': 'bgPrimary.secondary',
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'space-between',
    'borderRight': '1px solid',
    'borderColor': 'bgPrimary.tertiary',
    'boxSizing': 'border-box',
    'paddingY': [0, 0, 3, 3],
    'paddingLeft': [0, 0, 3, 3],
    'paddingRight': [0, 0, 2, 3],
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'bgSecondary.primary',
    },
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    height: 'fit-content',
    width: '100%',
  },
  bottom: {
    height: 'fit-content',
    width: '100%',
  },
};

export { styles as SideNavigationStyles };
