import { sxPropsToStyle, useStyles } from '@/lib/styles/styles.hooks';
import { LightBulbStyles } from '@/components/atoms/LightBulb/LightBulb.styles';
import { Box } from '@mui/material';

type T = {
  topOffset?: number;
  leftOffset?: number;
};

const LightBulb = ({ topOffset = 0, leftOffset = 0 }: T) => {
  const styles = useStyles(LightBulbStyles, { topOffset, leftOffset });
  return (
    <Box id={'bulb'} sx={styles.root}>
      <Box style={sxPropsToStyle(styles.shapeSmall)} />
      <Box style={sxPropsToStyle(styles.shapeMedium)} />
      <Box style={sxPropsToStyle(styles.shapeBig)} />
    </Box>
  );
};

export default LightBulb;
