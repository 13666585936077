import { useStyles } from '@/lib/styles/styles.hooks';
import { Button, ButtonProps, LinkBaseProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import Link from 'next/link';
import { FC } from 'react';
import { AppRoute } from '../../../_routes';
import ChevronRightIcon from '../Icons/ChevronRightIcon/ChevronRightIcon';
import { AppLinkStyles } from './AppLink.styles';

export interface AppLinkProps extends LinkBaseProps {
  /** Label text */
  label?: string;
  /** app route */
  route?: AppRoute | string;
  /** external link, overwritten by route prop */
  href?: string;
  /** has arrow icon */
  hasArrowIcon?: boolean;
  /** content variant */
  variant?: 'button' | 'text';
  /** element disabled */
  disabled?: boolean;
  /** button variant */
  buttonVariant?: 'text' | 'contained' | 'outlined' | 'tab';
  /** button color */
  buttonColor?: 'primary' | 'secondary' | 'accent';
  /** button Props */
  buttonProps?: ButtonProps;
  /** text variant */
  textVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body';
  /** color variant */
  color?: 'primary' | 'secondary' | 'accent' | 'core.main';
  /** style */
  sx?: SxProps<Theme>;
}

const AppLink: FC<AppLinkProps> = ({
  label,
  route,
  href = '/',
  textVariant = 'h5',
  hasArrowIcon = true,
  variant = 'text',
  disabled = false,
  buttonVariant,
  buttonColor,
  color = 'primary',
  buttonProps,
  sx,
}) => {
  const styles = useStyles(AppLinkStyles, { color, disabled }, sx);

  const getButtonElement = () => (
    <Button disabled={disabled} variant={buttonVariant} color={buttonColor} {...buttonProps}>
      <Box sx={styles.buttonContainer}>
        <Typography variant={textVariant}>{label}</Typography>
        {hasArrowIcon && <ChevronRightIcon />}
      </Box>
    </Button>
  );

  const getTextElement = () => (
    <Box sx={styles.linkContainer}>
      <Typography variant={textVariant}>{label}</Typography>
      {hasArrowIcon && <ChevronRightIcon />}
    </Box>
  );

  return (
    <Box sx={styles.root} className="AppLink-root">
      {!disabled && (
        <Link href={route || href || '/'} passHref style={{ textDecoration: 'none' }}>
          <>
            {variant === 'button' && getButtonElement()}
            {variant === 'text' && getTextElement()}
          </>
        </Link>
      )}
      {disabled && (
        <>
          {variant === 'button' && getButtonElement()}
          {variant === 'text' && getTextElement()}
        </>
      )}
    </Box>
  );
};

export default AppLink;
