import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    'height': '100%',
    'width': '100%',
    'position': 'relative',
    '.MuiAlert-action': {
      padding: 0,
      marginRight: 0,
      alignItems: 'center',
    },
  },
  bannerContainer: {
    'height': '100%',
    'width': '100%',
    '.MuiPaper-root.MuiAlert-root': {
      borderRadius: 0,
      paddingX: [3, 3, 4, 7],
      paddingY: [2],
      margin: 0,
      border: 'none',
      color: 'contentPrimary.primary',
    },
    '.MuiAlert-icon': {
      marginRight: [3],
    },
    '.MuiAlert-standardSuccess': {
      'backgroundColor': 'success.transparent',
      '.MuiAlert-icon': {
        color: 'success.main',
      },
    },
    '.MuiAlert-standardWarning': {
      'backgroundColor': 'warning.transparent',
      '.MuiAlert-icon': {
        color: 'warning.main',
      },
    },
    '.MuiAlert-standardError': {
      'backgroundColor': 'error.transparent',
      '.MuiAlert-icon': {
        color: 'error.main',
      },
    },
    '.MuiAlert-standardInfo': {
      'backgroundColor': 'info.transparent',
      '.MuiAlert-icon': {
        color: 'info.main',
      },
    },
  },
  closingButton: {
    padding: 0,
  },
};

export { styles as SystemBannerStyles };
