import { AthleteCard } from '@cstactics/types';
import { StateCreator } from 'zustand';
import {
  ItemFilterBarFilter,
  ItemFilterBarSortByOption,
  ItemFilterBarSortByOptions,
} from '@/components/molecules/ItemFilterBar/ItemFilterBar.types';
import { UiState } from '../ui.store';
import { athleteHubFilterState, athleteHubSortByOptions } from './athleteHub.filter';

export interface AthleteHubSlice {
  /** states */
  athleteHubFilterState: ItemFilterBarFilter<AthleteCard>[];
  athleteHubSortByState: ItemFilterBarSortByOption<AthleteCard>;
  athleteHubSortByOptions: ItemFilterBarSortByOptions<AthleteCard>;

  /** actions */
  setAthleteHubFilterState: (filters: ItemFilterBarFilter<AthleteCard>[]) => void;
  setAthleteHubSortByState: (sortByOption: ItemFilterBarSortByOption<AthleteCard>) => void;
}

export const createAthleteHubSlice: StateCreator<UiState, [], [], AthleteHubSlice> = (set) => ({
  /** states */
  athleteHubFilterState,
  athleteHubSortByOptions,
  athleteHubSortByState: athleteHubSortByOptions.options[0],
  playerState: {
    player: null,
  },

  /** actions */
  setAthleteHubFilterState: (athleteHubFilterState: ItemFilterBarFilter<AthleteCard>[]) => {
    set({ athleteHubFilterState });
  },
  setAthleteHubSortByState: (athleteHubSortByState: ItemFilterBarSortByOption<AthleteCard>) => {
    set({ athleteHubSortByState });
  },
});
