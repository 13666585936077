import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';
import { UserNotificationType } from '@cstactics/types';

interface AppNotificationStyleProps extends StyleStates {
  variant?: UserNotificationType;
  isLast: boolean;
}

const styles: ComponentStylesWithFns<AppNotificationStyleProps> = {
  root: ({ variant, isLast }) => [
    {
      padding: [3],
      display: 'flex',
      flexDirection: 'column',
      gap: [3],
      backgroundColor: 'bgPrimary.secondary',
    },
    variant === UserNotificationType.DEFAULT && {
      backgroundColor: 'bgPrimary.secondary',
    },
    variant === UserNotificationType.HIGHLIGHT && {
      backgroundColor: 'success.transparent',
    },
    variant === UserNotificationType.WARNING && {
      backgroundColor: 'warning.transparent',
    },
    isLast && {
      borderBottom: 'none',
    },
    !isLast && {
      borderBottom: '1px solid',
      borderBottomColor: 'contentSecondary.tertiary',
    },
  ],
};

export { styles as AppNotificationStyles };
