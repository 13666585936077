import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns = {
  root: {
    display: 'flex',
    gap: [1],
  },
};

export { styles as NotificationTimeTagStyles };
