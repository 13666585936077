import { Box, SxProps, Theme } from '@mui/material';
import Image, { StaticImageData } from 'next/legacy/image';
import { FC } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { AppImageStyles } from './AppImage.styles';

interface AppImageProps {
  /* src image url */
  src: string | StaticImageData;
  /* image alternative text */
  alt?: string;
  /* ratio width */
  ratioWidth?: 16 | 1 | 3 | 4 | 5 | 42;
  /* ratio height */
  ratioHeight?: 9 | 1 | 2 | 4 | 5 | 9;
  /* width */
  width?: number;
  /* height */
  height?: number;
  /* object position */
  objectPosition?: NonNullable<JSX.IntrinsicElements['img']['style']>['objectPosition'];
  /** style */
  sx?: SxProps<Theme>;
}

const AppImage: FC<AppImageProps> = ({ src, alt, ratioWidth, ratioHeight, objectPosition, width, height, sx }) => {
  const styles = useStyles(AppImageStyles, {}, sx);

  return (
    <Box sx={styles.root} className="AppImage-root">
      <Box sx={styles.imageContainer}>
        <Image
          src={src}
          width={ratioWidth || width}
          height={ratioHeight || height}
          layout={objectPosition ? 'fixed' : 'responsive'}
          objectFit={'cover'}
          objectPosition={objectPosition}
          alt={alt}
          className="AppImage"
        />
      </Box>
    </Box>
  );
};

export default AppImage;
