import { createSvgIcon, SxProps, Theme } from '@mui/material';
import { CsRole } from '@cstactics/types';
import { FC } from 'react';

import Captain from './captain.svg';
import EntryFragger from './entryFragger.svg';
import Flex from './flex.svg';
import Sniper from './sniper.svg';
import Support from './support.svg';

interface CsRoleIconProps {
  role: CsRole | string;

  /** styles */
  sx?: SxProps<Theme>;
}

const CaptainIcon = createSvgIcon(<Captain />, 'Captain');
const EntryFraggerIcon = createSvgIcon(<EntryFragger />, 'EntryFragger');
const FlexIcon = createSvgIcon(<Flex />, 'Flex');
const SniperIcon = createSvgIcon(<Sniper />, 'Sniper');
const SupportIcon = createSvgIcon(<Support />, 'Support');

const CsRoleIcon: FC<CsRoleIconProps> = ({ role, sx }) => {
  switch (role) {
    case CsRole.CAPTAIN:
      return <CaptainIcon sx={sx} />;
    case CsRole.ENTRY_FRAGGER:
      return <EntryFraggerIcon sx={sx} />;
    case CsRole.FLEX:
      return <FlexIcon sx={sx} />;
    case CsRole.SNIPER:
      return <SniperIcon sx={sx} />;
    case CsRole.SUPPORT:
      return <SupportIcon sx={sx} />;
    default:
      return <CaptainIcon sx={sx} />;
  }
};

export default CsRoleIcon;
