import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';

interface AppLinkStyleProps extends StyleStates {
  color: 'primary' | 'secondary' | 'core.main';
  disabled: boolean;
}

const styles: ComponentStylesWithFns<AppLinkStyleProps> = {
  root: () => [
    {
      display: 'flex',
      width: 'fit-content',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      textDecoration: 'none',
      transition: 'all .3s ease',
    },
  ],
  linkContainer: ({ color }) => [
    {
      display: 'flex',
      gap: '12px',
    },
    color === 'primary' && {
      'color': 'accent.main',
      '&:hover': {
        color: 'contentPrimary.primary',
      },
    },
    color === 'secondary' && {
      'color': 'contentPrimary.tertiary',
      '&:hover': {
        color: 'contentPrimary.primary',
      },
    },
    (color || '').indexOf('core') > -1 && {
      'color': color,
      '&:hover': {
        color: 'contentPrimary.primary',
      },
    },
  ],
  buttonContainer: {
    display: 'flex',
    gap: '12px',
  },
};

export { styles as AppLinkStyles };
