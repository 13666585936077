import { StateCreator } from 'zustand';
import { UiState } from '../ui.store';

export interface InventoryNotificationSlice {
  inventoryNotification: number;
  setInventoryNotification: (inventoryNotification: number) => void;
}

export const createInventoryNotificationSlice: StateCreator<UiState, [], [], InventoryNotificationSlice> = (set) => ({
  /** states */
  inventoryNotification: 0,
  /** actions */
  setInventoryNotification: (inventoryNotification: number) => set({ inventoryNotification }),
});
