import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Link, { LinkProps } from 'next/link';
import { FC } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { TabLinkStyles } from './TabLink.styles';

export interface TabLinkProps extends LinkProps {
  /** label */
  label?: string;
  /** icon element */
  icon?: JSX.Element;
  /** icon right element */
  iconRight?: JSX.Element;
  /** icon element */
  fullWidth?: boolean;
  /** styles */
  sx?: SxProps<Theme>;
}

const TabLink: FC<TabLinkProps> = ({ label, icon, iconRight, fullWidth = true, sx, ...linkProps }) => {
  const styles = useStyles(TabLinkStyles, { fullWidth: fullWidth }, sx);

  return (
    <Link {...linkProps} passHref legacyBehavior>
      <Box sx={styles.root} className="TabLink-root">
        {icon && <Box sx={styles.icon}>{icon}</Box>}
        {label && <Typography variant="h5">{label}</Typography>}
        {iconRight && <Box sx={styles.icon}>{iconRight}</Box>}
      </Box>
    </Link>
  );
};

export default TabLink;
