import { create } from 'zustand';
import { AthleteHubSlice, createAthleteHubSlice } from './athleteHub/athleteHub.slice';
import { createTeamBenchSlice, TeamBenchSlice } from './teamBench/teamBench.slice';
import { withDevtools } from '@/lib/store/utils';
import { createMatchSimulationSlice, MatchSimulationSlice } from '@/lib/store/ui/matchSimulation/matchSimulation.slice';
import {
  createInventoryNotificationSlice,
  InventoryNotificationSlice,
} from '@/lib/store/ui/inventoryNotification/inventoryNotification.slice';

export type UiState = AthleteHubSlice & TeamBenchSlice & MatchSimulationSlice & InventoryNotificationSlice;

export const useUiStore = create<UiState>()(
  withDevtools(
    (set, get, ...a) => ({
      ...createAthleteHubSlice(set, get, ...a),
      ...createTeamBenchSlice(set, get, ...a),
      ...createMatchSimulationSlice(set, get, ...a),
      ...createInventoryNotificationSlice(set, get, ...a),
    }),
    { name: 'UiState' }
  )
);
